import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { formatDateTh } from '../components/DatePickerField';
import { apiURL } from '../config';
import React from 'react';
import { Activity } from './interface';

type ActivityItemProps = {
  activity: Activity;
};
export function ActivityItem(props: ActivityItemProps) {
  const item = props.activity;
  return (
    <Card style={{ marginBottom: '10px', marginTop: '10px' }} key={item.id}>
      <Card.Body>
        <Card.Subtitle className="mb-2 text-muted">
          <span className="detail-subtitle">
            วันที่ {formatDateTh(item.date)} บันทึกโดย {item.createBy?.name}
          </span>
        </Card.Subtitle>
        <Card.Text>
          <span className="detail-desc">{item.description}</span>
        </Card.Text>
      </Card.Body>
      {item.attachments && item.attachments.length > 0 ? (
        <ListGroup className="list-group-flush">
          {item.attachments.map(attachment => {
            return (
              <ListGroupItem>
                <a
                  href={`${apiURL}/attachment/${attachment.fileName}`}
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    className="attachment"
                    style={{
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      padding: '5px',
                      width: '40px',
                    }}
                    alt={`document ${attachment.originalName}`}
                    src={`${apiURL}/attachment/${attachment.fileName}/thumb`}
                  />
                  <span className="detail-attachment">
                    {attachment.originalName}
                  </span>
                </a>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      ) : null}
    </Card>
  );
}
