import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_2,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QDISCriteriaKeys, QDISKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QDISDefinition = Definition &
  SubCriteriaDefinition<QDISCriteriaKeys>;

export const qDISDef: QDISDefinition = {
  id: 'qdis',
  need: 'ต้องการสวัสดิการทางการศึกษา',
  category: 'พิการ',
  label: `${PREFIX_2}พิการ`,
  subCriteriaLabel: 'ลักษณะความพิการ',
  subCriteriaChoices: {
    qdis_1_1: 'พิการทางร่างกาย',
    qdis_1_2: 'พิการทางสมอง',
    qdis_1_3: 'อื่นๆ',
  },
  subCriteriaKeys: QDISKeys,
};

export function copyQDISDependentValues(source: DataState, target: DataState) {
  if (source.qdis) {
    target.qdis = true;
    QDISKeys.forEach(k => (target[k] = source[k] || false));
    target.qdis_text = source.qdis_text;
  }
}

export function QDISDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qdis) {
    return (
      <div>
        {qDISDef.need} สำหรับ {PREFIX_2} {qDISDef.category} โดย{' '}
        {qDISDef.subCriteriaLabel} คือ
        <ul>
          {qDISDef.subCriteriaKeys.map((k: QDISCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qDISDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qdis_text ? <div>คำอธิบายเพิ่มเติม: {data.qdis_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QDIS(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qdis_1_3
    ? notEmpty(data.qdis_text)
    : data.qdis_1_1 || data.qdis_1_2;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qDISDef.label}</div>
        <SubCriteriaCheckboxes<QDISCriteriaKeys>
          definition={qDISDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qdis_text">
            <Form.Control
              type="text"
              value={data.qdis_text || ''}
              isInvalid={data.qdis_1_3 && empty(data.qdis_text)}
              onChange={(evt: any) => set('qdis_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
