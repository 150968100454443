import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_1,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QLRNCriteriaKeys, QLRNKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QLRNDefinition = Definition &
  SubCriteriaDefinition<QLRNCriteriaKeys>;

export const qLRNDef: QLRNDefinition = {
  id: 'qlrn',
  need: 'ต้องการสวัสดิการทางการศึกษา',
  category: 'มีปัญหาด้านการเรียนรู้ ( LD,พัฒนาการ, ออทิสติก,สมาธิสั้น)',
  label: `${PREFIX_1}มีปัญหาด้านการเรียนรู้ ( LD,พัฒนาการ, ออทิสติก,สมาธิสั้น)`,
  subCriteriaLabel: 'ลักษณะ',
  subCriteriaChoices: {
    qlrn_1_1: 'ยังไม่ได้หาหมอ',
    qlrn_1_2: 'โรงเรียนหรือผู้ปกครองไม่แน่ใจว่าจะรับมือยังไง',
    qlrn_1_3: 'อื่นๆ',
  },
  subCriteriaKeys: QLRNKeys,
};

export function copyQLRNDependentValues(source: DataState, target: DataState) {
  if (source.qlrn) {
    target.qlrn = true;
    QLRNKeys.forEach(k => (target[k] = source[k] || false));
    target.qlrn_text = source.qlrn_text;
  }
}

export function QLRNDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qlrn) {
    return (
      <div>
        {qLRNDef.need} กรณี {qLRNDef.category} {qLRNDef.subCriteriaLabel} คือ
        <ul>
          {qLRNDef.subCriteriaKeys.map((k: QLRNCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qLRNDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qlrn_text ? <div>คำอธิบายเพิ่มเติม: {data.qlrn_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QLRN(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qlrn_1_3
    ? notEmpty(data.qlrn_text)
    : data.qlrn_1_1 || data.qlrn_1_2;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qLRNDef.label}</div>
        <SubCriteriaCheckboxes<QLRNCriteriaKeys>
          definition={qLRNDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qlrn_text">
            <Form.Control
              type="text"
              value={data.qlrn_text || ''}
              isInvalid={data.qlrn_1_3 && empty(data.qlrn_text)}
              onChange={(evt: any) => set('qlrn_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
