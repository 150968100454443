import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_1,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QTRFCriteriaKeys, QTRFKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QTRFDefinition = Definition &
  SubCriteriaDefinition<QTRFCriteriaKeys>;

export const qTRFDef: QTRFDefinition = {
  id: 'qtrf',
  need: 'ถูกละเมิดหรือไม่มีคนคุ้มครอง',
  category: 'ขบวนการค้ามนุษย์ ',
  label: `${PREFIX_1}ขบวนการค้ามนุษย์`,
  subCriteriaLabel: 'ลักษณะความเสี่ยง',
  subCriteriaChoices: {
    qtrf_1_1: 'ขอทาน',
    qtrf_1_2: 'ขายบริการทางเพศ',
    qtrf_1_3: 'เด็กถูกล่วงละเมิดทางเพศ ทางกาย และหรือจิตใจ',
    qtrf_1_4: 'อื่นๆ',
  },
  subCriteriaKeys: QTRFKeys,
};

export function copyQTRFDependentValues(source: DataState, target: DataState) {
  if (source.qtrf) {
    target.qtrf = true;
    QTRFKeys.forEach(k => (target[k] = source[k] || false));
    target.qtrf_text = source.qtrf_text;
  }
}

export function QTRFDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qtrf) {
    return (
      <div>
        {qTRFDef.need} จาก {qTRFDef.category} {qTRFDef.subCriteriaLabel} คือ
        <ul>
          {qTRFDef.subCriteriaKeys.map((k: QTRFCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qTRFDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qtrf_text ? <div>คำอธิบายเพิ่มเติม: {data.qtrf_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QTRF(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qtrf_1_4
    ? notEmpty(data.qtrf_text)
    : data.qtrf_1_1 || data.qtrf_1_2 || data.qtrf_1_3;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qTRFDef.label}</div>
        <SubCriteriaCheckboxes<QTRFCriteriaKeys>
          definition={qTRFDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qtrf_text">
            <Form.Control
              type="text"
              value={data.qtrf_text || ''}
              isInvalid={data.qtrf_1_4 && empty(data.qtrf_text)}
              onChange={(evt: any) => set('qtrf_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
