import { DataState } from '../report/data';

export type Case = {
  id: number;
  name: string;
  age: string;
  gender: string;
  description: string;
  date: Date;
  data: DataState;
};

export type State = {
  pageNumber: number;
  cases: Case[];
  selected?: Case;
};

export type Action =
  | {
      type: 'back';
    }
  | {
      type: 'select';
      payload: Case;
    }
  | {
      type: 'cases';
      payload: Case[];
    };

export function reducer(oldState: State, action: Action): State {
  switch (action.type) {
    case 'cases':
      return {
        ...oldState,
        cases: action.payload,
      };
    case 'select':
      return {
        ...oldState,
        pageNumber: 1,
        selected: action.payload,
      };
    case 'back':
      return {
        ...oldState,
        pageNumber: 0,
      };
    default:
      return {
        ...oldState,
      };
  }
}
