import * as React from 'react';
import { Login } from './login';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';

export default function Public() {
  const history = useHistory();
  const savedUserId = sessionStorage.getItem('userId');
  if (savedUserId) {
    return <Redirect to="/" />;
  }

  const anonymousReport = () => {
    history.push('/public/report');
  };
  return (
    <Container>
      <Row className="justify-content-center" style={{ marginTop: 80 }}>
        <img src="/logo-srs.png" />
      </Row>
      <Row className="justify-content-center" style={{ marginTop: 20 }}>
        <Col lg={4} style={{ textAlign: 'center' }}>
          <div>
            รายงานปัญหาเด็กถูกละเมิด, ต้องการการคุ้มครอง, ขาดโอกาสทางการศึกษา
            ฯลฯ
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center" style={{ marginTop: 30 }}>
        <Col lg={4} style={{ textAlign: 'center' }}>
          <Button variant="outline-primary" onClick={anonymousReport}>
            รายงาน
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: 80 }} className="justify-content-center">
        <Col md="4">
          <div
            style={{ fontSize: '80%', padding: 20 }}
            className="justify-content-center"
          >
            หรือ login เข้าใช้งานระบบ smart refer
          </div>
          <Login
            onLoginSuccess={() => {
              history.push('/');
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}
