import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_3,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QHOUCriteriaKeys, QHOUKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QHOUDefinition = Definition &
  SubCriteriaDefinition<QHOUCriteriaKeys>;

export const qHOUDef: QHOUDefinition = {
  id: 'qhou',
  need: 'ต้องการความช่วยเหลือด้านอื่นๆ',
  category: 'ที่อยู่ที่เหมาะสม',
  label: `${PREFIX_3}ที่อยู่ที่เหมาะสม`,
  subCriteriaLabel: 'ลักษณะ',
  subCriteriaChoices: {
    qhou_1_1: 'บ้านพักที่ไม่มั่นคงเเข็งแรง',
    qhou_1_2: 'ไม่ถูกสุขลักษณะ',
    qhou_1_3: 'อยู่ในสภาพแวดล้อมที่ไม่เหมาะสม',
    qhou_1_4: 'ไม่มีผู้ใหญ่คอยช่วยเหลือ',
    qhou_1_5: 'อื่นๆ',
  },
  subCriteriaKeys: QHOUKeys,
};

export function copyQHOUDependentValues(source: DataState, target: DataState) {
  if (source.qhou) {
    target.qhou = true;
    QHOUKeys.forEach(k => (target[k] = source[k] || false));
    target.qhou_text = source.qhou_text;
  }
}

export function QHOUDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qhou) {
    return (
      <div>
        {qHOUDef.need} กรณี {qHOUDef.category} {qHOUDef.subCriteriaLabel} คือ
        <ul>
          {qHOUDef.subCriteriaKeys.map((k: QHOUCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qHOUDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qhou_text ? <div>คำอธิบายเพิ่มเติม: {data.qhou_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QHOU(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qhou_1_5
    ? notEmpty(data.qhou_text)
    : data.qhou_1_1 || data.qhou_1_2 || data.qhou_1_3 || data.qhou_1_4;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qHOUDef.label}</div>
        <SubCriteriaCheckboxes<QHOUCriteriaKeys>
          definition={qHOUDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qhou_text">
            <Form.Control
              type="text"
              value={data.qhou_text || ''}
              isInvalid={data.qhou_1_5 && empty(data.qhou_text)}
              onChange={(evt: any) => set('qhou_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
