import * as React from 'react';
import { getPageInitialState, pageReducer } from '../report/page';
import { dataReducer, getDefaultDataState } from '../report/data';
import GeneralPage from '../report/generalPage';
import TopicPage from '../report/topicPage';
import SubTopicPage from '../report/subTopicPage';
import ConfirmPage from '../report/confirmPage';
import { Fragment } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function AnonymousReport() {
  const [{ page }, dispatch] = React.useReducer(
    pageReducer,
    getPageInitialState(),
  );

  const [data, dataDispatch] = React.useReducer(
    dataReducer,
    getDefaultDataState(),
  );

  const history = useHistory();
  const [message, setMessage] = React.useState('Loading...');

  return (
    <Fragment>
      <Navbar bg="light" expand={true}>
        <Navbar.Brand href="/">
          <img src="/logo-srs.png" />
        </Navbar.Brand>
      </Navbar>
      <div>
        {page === 'general' ? (
          <GeneralPage
            data={data}
            dispatch={dataDispatch}
            onNext={() => dispatch({ type: 'next' })}
            geoLocation={true}
          />
        ) : null}

        {page === 'topic' ? (
          <TopicPage
            data={data}
            dispatch={dataDispatch}
            onNext={() => {
              dispatch({ type: 'next' });
              dataDispatch({
                type: 'setNumber',
                name: 'subPageIndex',
                value: 0,
              });
            }}
            onBack={() => dispatch({ type: 'back' })}
          />
        ) : null}
        {page === 'subTopic' ? (
          <SubTopicPage
            data={data}
            dispatch={dataDispatch}
            onNext={() => dispatch({ type: 'next' })}
            onBack={() => dispatch({ type: 'back' })}
          />
        ) : null}
        {page === 'confirm' ? (
          <ConfirmPage
            data={data}
            basicInfo={{}}
            onBack={() => dispatch({ type: 'back' })}
            onSubmit={() => console.log('submit')}
            webapp={true}
          />
        ) : null}
      </div>
    </Fragment>
  );
}
