import React from 'react';
import axios from 'axios';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { Case } from './state';
import { DetailForm } from './detailForm';
import { formatDateTh } from '../components/DatePickerField';
import { apiURL } from '../config';
import {
  Activity,
  FetchActivitiesResponse,
  GetPersonResponse,
  GetPlanResponse,
} from './interface';
import { ActivityItem } from './activityItem';
import { BasicInfoDataState } from '../components/PersonForm';
import { BasicInfoForm } from './basicInfoForm';
import Plan, { PlanState } from './Plan';

type DetailProps = {
  detail: Case | undefined;
  onBack: () => void;
};

const basicInfoInitialState: BasicInfoDataState = {};
const planInitialState: PlanState = {};

const fetchActivities = (reportId: number) => {
  return axios
    .request<FetchActivitiesResponse>({
      url: `${apiURL}/managedReports/${reportId}/activities`,
      method: 'get',
      timeout: 15000,
    })
    .then(({ data }) => {
      return data.activities.map(a => ({
        ...a,
        date: new Date(a.date),
      }));
    });
};

const getPerson = (reportId: number) => {
  return axios
    .request<GetPersonResponse>({
      url: `${apiURL}/managedReports/${reportId}/person`,
      method: 'get',
      timeout: 15000,
    })
    .then(({ data }) => {
      if (data.success && data.data) {
        const p = data.data;
        return {
          ...p,
          birthDate: p.birthDate ? new Date(p.birthDate) : undefined,
          createdDate: p.createdDate ? new Date(p.createdDate) : undefined,
          updatedDate: p.updatedDate ? new Date(p.updatedDate) : undefined,
        };
      }
      return {};
    });
};

const getPlan = (reportId: number) => {
  return axios
    .request<GetPlanResponse>({
      url: `${apiURL}/managedReports/${reportId}/plan`,
      method: 'get',
      timeout: 15000,
    })
    .then(({ data }) => {
      if (data.success && data.data) {
        const p = data.data;
        if (p.createdDate) {
          p.createdDate = new Date(p.createdDate);
        }
        if (p.updatedDate) {
          p.updatedDate = new Date(p.updatedDate);
        }
        return p;
      }
      return {};
    });
};

function highlight(content: string, word: string): string {
  return content.replace(
    new RegExp(word, 'g'),
    `<span style="font-weight: 500; background-color: moccasin">${word}</span>`,
  );
}

export function Detail(props: DetailProps) {
  const detail = props.detail || ({} as Case);
  const [activities, setActivities] = React.useState(new Array<Activity>());
  const [showActivities, setShowActivities] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [basicInfo, setBasicInfo] = React.useState(basicInfoInitialState);
  const [plan, setPlan] = React.useState(planInitialState);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const reportId = detail.id;
    const err = (e: any) => {
      setError(e.toString());
    };
    fetchActivities(reportId)
      .then(setActivities)
      .catch(err);
    getPerson(reportId)
      .then(setBasicInfo)
      .catch(err);
    getPlan(reportId)
      .then(setPlan)
      .catch(err);
  }, [detail.id]);

  const activityList = activities.map(item => <ActivityItem activity={item} />);

  const onSaveBasicInfo = (data: BasicInfoDataState) => {
    setBasicInfo({
      ...data,
      createdDate: data.createdDate ? new Date(data.createdDate) : undefined,
      updatedDate: data.updatedDate ? new Date(data.updatedDate) : undefined,
    });
  };

  let description = detail.description;
  if (description) {
    description = highlight(description, 'ต้องการความช่วยเหลือด้านอื่นๆ');
    description = highlight(description, 'ถูกละเมิดหรือไม่มีคนคุ้มครอง');
    description = highlight(description, 'ต้องการสวัสดิการทางการศึกษา');
  }

  return (
    <>
      {pageNumber === 0 ? (
        <Container>
          {error !== null ? <Alert variant="danger">{error}</Alert> : null}
          <Row className="py-2">
            <Col>
              <Button variant="light" onClick={props.onBack}>
                &lt; Back
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <span className="detail-title">ข้อมูลที่ได้จากการรับแจ้ง</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="detail-subtitle">
                รับแจ้ง: {formatDateTh(detail.date)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                className="border-top border-bottom py-3 detail-desc"
                dangerouslySetInnerHTML={{
                  __html: description || '',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BasicInfoForm
                reportId={detail.id}
                onSave={onSaveBasicInfo}
                data={basicInfo}
                setData={setBasicInfo}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Plan
                reportId={detail.id}
                data={plan}
                setData={setPlan}
                report={detail}
              />
            </Col>
          </Row>
          <Row className="mt-3 ">
            <Col>
              <div className="d-flex align-items-baseline mb-3">
                <div>
                  <div className="detail-title">บันทึกเหตุการณ์/กิจกรรม</div>
                </div>
                <div className="ml-auto">
                  <Button
                    className="rounded-circle"
                    variant="secondary"
                    onClick={() => setShowActivities(!showActivities)}
                  >
                    {showActivities ? <span>▲</span> : <span>▼</span>}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {showActivities ? (
                activityList
              ) : (
                <div>
                  {activityList.length > 0 ? (
                    <Alert
                      variant="success"
                      onClick={() => setShowActivities(true)}
                    >
                      <span className="detail-subtitle">
                        มีบันทึกกิจกรรมจำนวน {activityList.length} ครั้ง
                        กดเพื่อดู
                      </span>
                    </Alert>
                  ) : (
                    <Alert variant="warning" onClick={() => setPageNumber(1)}>
                      <span className="detail-subtitle">
                        ไม่มีข้อมูล กดเพื่อบันทึกกิจกรรม
                      </span>
                    </Alert>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row className="py-4">
            <Col>
              <Button variant="light" onClick={props.onBack}>
                &lt; Back
              </Button>
            </Col>
            <Col className="text-right">
              <Button onClick={() => setPageNumber(1)}>
                + เหตุการณ์/กิจกรรม
              </Button>
            </Col>
          </Row>
        </Container>
      ) : null}
      {pageNumber === 1 ? (
        <DetailForm
          reportId={detail.id}
          onBack={result => {
            if (result) {
              fetchActivities(detail.id).then(setActivities);
            }
            setPageNumber(0);
          }}
        />
      ) : null}
    </>
  );
}
