import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePickerField from './DatePickerField';
import AsyncButton from './AsyncButton';
import { DataAction } from '../report/data';
import { PageAction } from '../report/page';

function Mandatory() {
  return <span style={{ color: 'red' }}>*</span>;
}

type field = {
  [key: string]: any;
};

type Choice = {
  key: string;
  label: string;
};

const NationalityKeys = [
  { key: 'thai', label: 'มีสัญชาติไทย' },
  { key: 'none', label: 'ไม่มีสัญชาติ' },
  { key: 'other', label: 'มีสัญชาติอื่น...' },
  { key: 'unknown', label: 'ไม่ทราบข้อมูล' },
] as const;
type NationalityValues = typeof NationalityKeys[number]['key'];

const ParentRelationKeys = [
  { key: 'together', label: 'อยู่ร่วมกัน (พ่อแม่ลูก)' },
  { key: 'separate', label: 'บิดามารดาหย่าร้าง' },
] as const;
type ParentRelationValues = typeof ParentRelationKeys[number]['key'];

const GuardianKeys = [
  { key: 'father', label: 'บิดา' },
  { key: 'mother', label: 'มารดา' },
  { key: 'stepParent', label: 'พ่อเลี้ยง/แม่เลี้ยง' },
  { key: 'relative', label: 'ญาติ...' },
  { key: 'other', label: 'อื่นๆ...' },
] as const;
type GuardianValues = typeof GuardianKeys[number]['key'];

const HomeStatusKeys = [
  { key: 'withFamily', label: 'เด็กอาศัยอยู่ในบ้านกับครอบครัว/ผู้ดูแล' },
  { key: 'stayAlone', label: 'เด็กอยู่โดยลำพังโดยไม่มีผู้ใหญ่อยู่ด้วย' },
  {
    key: 'homeless',
    label:
      'เด็กไม่ได้อาศัยอยู่ในบ้าน (เช่น เด็กเร่ร่อน เด็กในสถานสงเคราะห์ สถานพินิจ วัด/โบสถ์ องค์กร ฯลฯ',
  },
] as const;
type HomeStatusValues = typeof HomeStatusKeys[number]['key'];

const HomelessCauseKeys = [
  { key: 'parentSeparated', label: 'พ่อแม่แยกกันอยู่/หย่าร้าง' },
  { key: 'beingAbandoned', label: 'ถูกทอดทิ้งแต่กำเนิด' },
  { key: 'parentDead', label: 'พ่อ/แม่เสียชีวิต' },
  { key: 'moveOutForWork', label: 'ออกจากบ้านมาศึกษาหรือทำงานต่อ' },
  { key: 'other', label: 'อื่นๆ...' },
] as const;
type HomelessCauseValues = typeof HomelessCauseKeys[number]['key'];

const CommunityPeriodKeys = [
  { key: 'sinceBorn', label: 'อยู่ตั้งแต่เกิด' },
  { key: 'lessThan6Mos', label: 'เพิ่งย้ายเข้ามาอยู่ในชุมชนไม่ถึง 6 เดือน' },
  { key: 'moreThan6Mos', label: 'ย้ายเข้ามาอยู่ในชุมชนมากกว่า 6 เดือน' },
] as const;
type CommunityPeriodValues = typeof CommunityPeriodKeys[number]['key'];

const FamilyEventKeys = [
  { key: 'none', label: 'ไม่มี' },
  { key: 'guardianSick', label: 'ผู้ดูแล/ผู้ปกครอง พ่อแม่ป่วยหนัก' },
  { key: 'guardianDead', label: 'ผู้ดูแล/ผู้ปกครอง เสียชีวิต' },
  { key: 'houseMoved', label: 'ครัวเรือนย้ายที่อยู่อาศัย' },
  { key: 'guardianChanged', label: 'เด็กเปลี่ยนผู้ดูแลหลัก' },
  { key: 'naturalDisaster', label: 'ภัยพิบัติ เช่น ไฟไหม้ น้ำท่วม พายุ' },
  { key: 'localUnrest', label: 'เกิดเหตุการณ์รุนแรง/ความไม่สงบในพื้นที่' },
  { key: 'parentImprisoned', label: 'พ่อหรือแม่โดนจับ' },
  { key: 'other', label: 'อื่นๆ...' },
] as const;
type FamilyEventValues = typeof FamilyEventKeys[number]['key'];

export type BasicInfoDataState = {
  firstName?: string;
  lastName?: string;
  citizenId?: string;
  birthDate?: Date;
  age?: string;
  gender?: string;
  height?: string;
  weight?: string;
  nationality?: NationalityValues;
  nationalityMore?: string;
  addrNo?: string;
  addrVillage?: string;
  addrSubDistrict?: string;
  addrDistrict?: string;
  addrProvince?: string;
  phoneNo?: string;
  facebook?: string;
  hometown?: string;
  numOfMembers?: string;
  parentRelation?: ParentRelationValues;
  fatherName?: string;
  motherName?: string;
  guardian?: GuardianValues;
  guardianMore?: string;
  homeStatus?: HomeStatusValues;
  homelessCause?: string; // combination of HomelessCauseValues
  homelessCauseMore?: string;
  communityPeriod?: CommunityPeriodValues;
  familyEvent?: FamilyEventValues; // combination of FamilyEventValues
  familyEventMore?: string;
  updatedDate?: Date;
  createdDate?: Date;
};

export type PersonFormProps = {
  data: BasicInfoDataState;
  setData: (b: BasicInfoDataState) => void;
  onSubmit?: () => Promise<void>;
};

export default function PersonForm(props: PersonFormProps) {
  const { data, setData, onSubmit } = props;

  const setValue = (key: string, value: string | number | Date) => {
    console.log('k/v', key, value);
    const tmp = { ...data };
    if (key === 'homeStatus' && value !== 'homeless') {
      console.log('del homelessCause');
      delete tmp.homelessCause;
      delete tmp.homelessCauseMore;
    } else if (key === 'nationality' && value !== 'other') {
      delete tmp.nationalityMore;
    } else if (key === 'guardian' && value !== 'other') {
      delete tmp.guardianMore;
    } else if (key === 'birthDate') {
      if (value && value instanceof Date) {
        const currentYear = new Date().getFullYear();
        let age = currentYear - value.getFullYear();
        if (age === 0) {
          age = 1;
        }
        tmp.age = age.toString();
      }
    }
    console.log(tmp);
    setData({
      ...tmp,
      [key]: value,
    });
  };

  const setCheckboxValue = (key: string, checked: boolean, value: string) => {
    const val = (data as field)[key];
    let arr: string[] = val ? val.split(',') : [];
    const idx = arr.findIndex(it => it === value);
    const empty = idx === -1;
    if (checked) {
      if (empty) {
        arr.push(value);
      }
    } else {
      if (!empty) {
        arr.splice(idx, 1);
      }
    }
    setValue(key, arr.join(','));
  };

  return (
    <Form className="pb-3">
      <div className="question-group mb-3">
        <Form.Row>
          <div className="question-group-title"></div>
          <Form.Group as={Col} md={6} controlId="firstName">
            <Form.Label>
              ชื่อ <Mandatory />
            </Form.Label>
            <Form.Control
              type="text"
              value={data.firstName}
              isInvalid={data.firstName === undefined || data.firstName === ''}
              onChange={(evt: any) => setValue('firstName', evt.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md={6} controlId="lastName">
            <Form.Label>
              นามสกุล <Mandatory />
            </Form.Label>
            <Form.Control
              type="text"
              value={data.lastName}
              isInvalid={data.lastName === undefined || data.lastName === ''}
              onChange={(evt: any) => setValue('lastName', evt.target.value)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={6} controlId="citizenId">
            <Form.Label>เลขประจําตัว 13 หลัก</Form.Label>
            <Form.Control
              type="text"
              value={data.citizenId}
              onChange={(evt: any) => setValue('citizenId', evt.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md={6} controlId="birthDate">
            <Form.Label>วันเดือนปีเกิด</Form.Label>
            <DatePickerField
              allowEmpty
              value={data.birthDate}
              onChange={d => setValue('birthDate', d)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={3} controlId="age">
            <Form.Label>
              อายุ (ปี) <Mandatory />
            </Form.Label>
            <Form.Control
              type="number"
              value={data.age}
              isInvalid={
                data.age === undefined ||
                data.age === '' ||
                parseInt(data.age) > 18
              }
              onChange={(evt: any) => setValue('age', evt.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md={3} controlId="gender">
            <Form.Label>
              เพศ <Mandatory />
            </Form.Label>
            <Form.Control
              as="select"
              value={data.gender || ''}
              isInvalid={data.gender === undefined || data.gender === ''}
              onChange={(evt: any) => setValue('gender', evt.target.value)}
              custom="true"
            >
              <option value="" />
              <option value="male">ชาย</option>
              <option value="female">หญิง</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={3} controlId="height">
            <Form.Label>ส่วนสูง (ซ.ม.)</Form.Label>
            <Form.Control
              type="number"
              value={data.height}
              onChange={(evt: any) => setValue('height', evt.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md={3} controlId="weight">
            <Form.Label>น้ำหนัก (ก.ก.)</Form.Label>
            <Form.Control
              type="number"
              value={data.weight}
              onChange={(evt: any) => setValue('weight', evt.target.value)}
            />
          </Form.Group>
        </Form.Row>
      </div>
      <div className="question-group mb-3">
        <div className="question-group-title">
          สถานะบุคคล <Mandatory />
        </div>
        <div className="choices">
          <div className="mb-3" />
          {NationalityKeys.map((it: Choice, idx: number) => {
            return (
              <div className="mb-3" key={it.key}>
                <Form.Check
                  custom
                  id={`na-${it.key}-${idx}`}
                  type="radio"
                  checked={data.nationality === it.key}
                  label={it.label}
                  onChange={() => setValue('nationality', it.key)}
                />
              </div>
            );
          })}
          <Form.Group controlId="nationalityMore">
            <Form.Label>... ระบุอื่นๆเพิ่มเติม</Form.Label>
            <Form.Control
              type="text"
              value={data.nationalityMore || ''}
              isInvalid={
                data.nationality === 'other' &&
                (data.nationalityMore === undefined ||
                  data.nationalityMore === null ||
                  data.nationalityMore === '')
              }
              onChange={(evt: any) =>
                setValue('nationalityMore', evt.target.value)
              }
            />
          </Form.Group>
        </div>
      </div>
      <div className="question-group mb-3">
        <div className="question-group-title">ที่อยู่ปัจจุบันของเด็ก</div>
        <div className="choices">
          <Form.Row>
            <Form.Group as={Col} md={4} controlId="addrNo">
              <Form.Label>บ้านเลขที่</Form.Label>
              <Form.Control
                type="text"
                value={data.addrNo || ''}
                onChange={(evt: any) => setValue('addrNo', evt.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={4} controlId="addrVillage">
              <Form.Label>หมู่ที่</Form.Label>
              <Form.Control
                type="text"
                value={data.addrVillage || ''}
                onChange={(evt: any) =>
                  setValue('addrVillage', evt.target.value)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md={4} controlId="addrSubDistrict">
              <Form.Label>
                ตำบล <Mandatory />
              </Form.Label>
              <Form.Control
                type="text"
                value={data.addrSubDistrict || ''}
                isInvalid={
                  data.addrSubDistrict === undefined ||
                  data.addrSubDistrict === ''
                }
                onChange={(evt: any) =>
                  setValue('addrSubDistrict', evt.target.value)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="addrDistrict">
              <Form.Label>
                อำเภอ <Mandatory />
              </Form.Label>
              <Form.Control
                type="text"
                value={data.addrDistrict || ''}
                isInvalid={
                  data.addrDistrict === undefined || data.addrDistrict === ''
                }
                onChange={(evt: any) =>
                  setValue('addrDistrict', evt.target.value)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="addrProvince">
              <Form.Label>
                จังหวัด <Mandatory />
              </Form.Label>
              <Form.Control
                type="text"
                value={data.addrProvince || ''}
                isInvalid={
                  data.addrProvince === undefined || data.addrProvince === ''
                }
                onChange={(evt: any) =>
                  setValue('addrProvince', evt.target.value)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="phoneNo">
              <Form.Label>เบอร์โทรศัพท์ที่สามารถติดต่อได้</Form.Label>
              <Form.Control
                type="text"
                value={data.phoneNo || ''}
                onChange={(evt: any) => setValue('phoneNo', evt.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="facebook">
              <Form.Label>Facebook</Form.Label>
              <Form.Control
                type="text"
                value={data.facebook || ''}
                onChange={(evt: any) => setValue('facebook', evt.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="hometown">
              <Form.Label>ภูมิลำเนาเดิม (มาจาก)</Form.Label>
              <Form.Control
                type="text"
                value={data.hometown || ''}
                onChange={(evt: any) => setValue('hometown', evt.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="numOfMembers">
              <Form.Label>จำนวนสมาชิกที่อยู่บ้านเดียวกัน (คน)</Form.Label>
              <Form.Control
                type="number"
                value={data.numOfMembers || ''}
                onChange={(evt: any) =>
                  setValue('numOfMembers', evt.target.value)
                }
              />
            </Form.Group>
          </Form.Row>
        </div>
      </div>
      <div className="question-group mb-3">
        <div className="question-group-title">
          สถานภาพครอบครัว <Mandatory />
        </div>
        <div className="choices">
          <div className="mb-3" />
          {ParentRelationKeys.map((it: Choice, idx: number) => {
            return (
              <div className="mb-3" key={it.key}>
                <Form.Check
                  custom
                  id={`pr-${it.key}-${idx}`}
                  type="radio"
                  checked={data.parentRelation === it.key}
                  label={it.label}
                  onChange={() => setValue('parentRelation', it.key)}
                />
              </div>
            );
          })}
          <Form.Row>
            <Form.Group as={Col} md={6} controlId="fatherName">
              <Form.Label>ชื่อสกุลบิดา</Form.Label>
              <Form.Control
                type="text"
                value={data.fatherName || ''}
                onChange={(evt: any) =>
                  setValue('fatherName', evt.target.value)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="motherName">
              <Form.Label>ชื่อสกุลมารดา</Form.Label>
              <Form.Control
                type="text"
                value={data.motherName || ''}
                onChange={(evt: any) =>
                  setValue('motherName', evt.target.value)
                }
              />
            </Form.Group>
          </Form.Row>
        </div>
      </div>
      <div className="question-group mb-3">
        <div className="question-group-title">
          ผู้ดูแลเด็กเป็นหลักคือ <Mandatory />
        </div>
        <div className="choices">
          <div className="mb-3" />
          {GuardianKeys.map((it: Choice, idx: number) => {
            return (
              <div className="mb-3" key={it.key}>
                <Form.Check
                  custom
                  id={`cg-${it.key}-${idx}`}
                  type="radio"
                  checked={data.guardian === it.key}
                  label={it.label}
                  onChange={() => setValue('guardian', it.key)}
                />
              </div>
            );
          })}
          <Form.Group controlId="guardianMore">
            <Form.Label>
              ... ระบุญาติหรืออื่นๆเพิ่มเติม (เกี่ยวข้องกับเด็กอย่างไร)
            </Form.Label>
            <Form.Control
              type="text"
              value={data.guardianMore || ''}
              isInvalid={
                data.guardian !== undefined &&
                (data.guardian === 'relative' || data.guardian === 'other') &&
                (data.guardianMore === undefined ||
                  data.guardianMore === null ||
                  data.guardianMore === '')
              }
              onChange={(evt: any) =>
                setValue('guardianMore', evt.target.value)
              }
            />
          </Form.Group>
        </div>
      </div>
      <div className="question-group mb-3">
        <div className="question-group-title">
          สถานะครอบครัว <Mandatory />
        </div>
        <div className="choices">
          <div className="mb-3" />
          {HomeStatusKeys.map((it: Choice, idx: number) => {
            return (
              <div className="mb-3" key={it.key}>
                <Form.Check
                  custom
                  id={`hs-${it.key}-${idx}`}
                  type="radio"
                  checked={data.homeStatus === it.key}
                  label={it.label}
                  onChange={() => {
                    setValue('homeStatus', it.key);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="question-group-subtitle">
          (สืบเนื่องจากคำถามด้านบน) กรณีที่เด็กไม่ได้อาศัยอยู่ในบ้าน
          สาเหตุที่เด็กไม่ได้อาศัยอยู่ในบ้านคือ (ระบุได้มากกว่า 1 ข้อ){' '}
          {/*เป็น bug component ไม่ rerender เลยต้องใส่ hidden อันนี้ไว้*/}
          <span style={{ visibility: 'hidden' }}>
            {data.homelessCause ? data.homelessCause.substring(0, 1) : ''}
          </span>
        </div>
        <div className="choices">
          <div className="mb-3" />
          {HomelessCauseKeys.map((it: Choice, idx: number) => {
            return (
              <div className="mb-3" key={it.key}>
                <Form.Check
                  custom
                  id={`hc-${it.key}-${idx}`}
                  type="checkbox"
                  disabled={data.homeStatus !== 'homeless'}
                  checked={
                    data.homelessCause
                      ? data.homelessCause.indexOf(it.key) > -1
                      : false
                  }
                  label={it.label}
                  onChange={(evt: any) =>
                    setCheckboxValue(
                      'homelessCause',
                      evt.target.checked,
                      it.key,
                    )
                  }
                />
              </div>
            );
          })}
          <Form.Group controlId="homelessCauseMore">
            <Form.Label>... ระบุอื่นๆเพิ่มเติม</Form.Label>
            {/*เป็น bug component ไม่ rerender เลยต้องใส่ hidden อันนี้ไว้*/}
            <span style={{ visibility: 'hidden' }}>
              {data.homelessCauseMore
                ? data.homelessCauseMore.substring(0, 1)
                : ''}
            </span>
            <Form.Control
              type="text"
              value={data.homelessCauseMore || ''}
              isInvalid={
                data.homelessCause !== undefined &&
                data.homelessCause !== null &&
                data.homelessCause.indexOf('other') >= 0 &&
                (data.homelessCauseMore === undefined ||
                  data.homelessCauseMore === null ||
                  data.homelessCauseMore === '')
              }
              onChange={(evt: any) =>
                setValue('homelessCauseMore', evt.target.value)
              }
            />
          </Form.Group>
        </div>
      </div>
      <div className="question-group mb-3">
        <div className="question-group-title">
          ระยะเวลาที่เด็กอาศัยอยู่ในชุมชน <Mandatory />
        </div>
        <div className="choices">
          <div className="mb-3" />
          {CommunityPeriodKeys.map((it: Choice, idx: number) => {
            return (
              <div className="mb-3" key={it.key}>
                <Form.Check
                  custom
                  id={`cp-${it.key}-${idx}`}
                  type="radio"
                  checked={data.communityPeriod === it.key}
                  label={it.label}
                  onChange={() => setValue('communityPeriod', it.key)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="question-group mb-3">
        <div className="question-group-title">
          เหตุการณ์สำคัญของครอบครัวในช่วง 6 เดือนที่ผ่านมา (ตอบได้มากกว่า 1 ข้อ){' '}
          <Mandatory />
        </div>
        <div className="choices">
          <div className="mb-3" />
          {FamilyEventKeys.map((it: Choice, idx: number) => {
            return (
              <div className="mb-3" key={it.key}>
                <Form.Check
                  custom
                  id={`fe-${it.key}-${idx}`}
                  type="checkbox"
                  checked={
                    data.familyEvent
                      ? data.familyEvent.indexOf(it.key) > -1
                      : false
                  }
                  label={it.label}
                  onChange={(evt: any) =>
                    setCheckboxValue('familyEvent', evt.target.checked, it.key)
                  }
                />
              </div>
            );
          })}
          <Form.Group controlId="homelessCauseMore">
            <Form.Label>
              ... ระบุอื่นๆเพิ่มเติม {data.familyEventMore}
            </Form.Label>
            <Form.Control
              type="text"
              value={data.familyEventMore || ''}
              isInvalid={
                data.familyEvent !== undefined &&
                data.familyEvent !== null &&
                data.familyEvent.indexOf('other') >= 0 &&
                (data.familyEventMore === undefined ||
                  data.familyEventMore === null ||
                  data.familyEventMore === '')
              }
              onChange={(evt: any) =>
                setValue('familyEventMore', evt.target.value)
              }
            />
          </Form.Group>
        </div>
      </div>
      {onSubmit ? (
        <Row>
          <Col className="text-right">
            <AsyncButton variant="primary" onClick={onSubmit}>
              บันทึก
            </AsyncButton>
          </Col>
        </Row>
      ) : null}
    </Form>
  );
}

export function checkValidate(basicInfo: BasicInfoDataState) {
  const errors = [];
  if (!basicInfo.firstName || basicInfo.firstName === '') {
    errors.push('กรุณาระบุชื่อ');
  }
  if (!basicInfo.lastName || basicInfo.lastName === '') {
    errors.push('กรุณาระบุนามสกุล');
  }
  if (!basicInfo.age || basicInfo.age === '0') {
    errors.push('กรุณาระบุอายุ');
  }
  if (basicInfo.age) {
    const tmp = parseInt(basicInfo.age);
    if (typeof tmp === 'number' && (tmp > 18 || tmp < 0)) {
      errors.push('ช่วงอายุไม่ถูกต้อง (1 ถึง 18 ปี)');
    }
  }

  if (!basicInfo.gender || basicInfo.gender.length === 0) {
    errors.push('กรุณาระบุเพศ');
  }
  if (!basicInfo.nationality) {
    errors.push('กรุณาระบุสถานะบุคคล');
  }
  if (basicInfo.nationality === 'other' && !basicInfo.nationalityMore) {
    errors.push('กรุณาระบุสถานะบุคคล เพิ่มเติม');
  }

  if (!basicInfo.addrSubDistrict) {
    errors.push('กรุณาระบุตำบลที่อยู่ของเด็ก');
  }
  if (!basicInfo.addrDistrict) {
    errors.push('กรุณาระบุอำเภอที่อยู่ของเด็ก');
  }
  if (!basicInfo.addrProvince) {
    errors.push('กรุณาระบุจังหวัดที่อยู่ของเด็ก');
  }

  if (!basicInfo.parentRelation) {
    errors.push('กรุณาระบุสถานะภาพครอบครัว');
  }

  if (!basicInfo.guardian) {
    errors.push('กรุณาใส่ข้อมูลผู้ดูแลเด็ก');
  }
  if (
    (basicInfo.guardian === 'other' || basicInfo.guardian === 'relative') &&
    !basicInfo.guardianMore
  ) {
    errors.push(
      'กรุณาใส่ข้อมูลผู้ดูแลเด็กเพิ่มเติม (ระบุความเกี่ยวข้องกับเด็ก)',
    );
  }

  //==========
  if (!basicInfo.homeStatus) {
    errors.push('กรุณาใส่ข้อมูล สถานะครอบครัว');
  }
  if (basicInfo.homeStatus === 'homeless' && !basicInfo.homelessCause) {
    errors.push('กรุณาระบุสาเหตุที่เด็กไม่ได้อาศัยอยู่ในบ้าน');
  }
  if (
    basicInfo.homeStatus === 'homeless' &&
    basicInfo.homelessCause &&
    basicInfo.homelessCause.indexOf('other') >= 0 &&
    !basicInfo.homelessCauseMore
  ) {
    errors.push('กรุณาใส่ข้อมูล สาเหตุที่เด็กไม่ได้อาศัยอยู่ในบ้าน เพิ่มเติม');
  }
  //==========

  if (!basicInfo.communityPeriod) {
    errors.push('กรุณาระบุระยะเวลาที่เด็กอาศัยอยู่ในชุมชน');
  }

  if (!basicInfo.familyEvent) {
    errors.push('กรุณาระบุเหตุการณ์สำคัญของครอบครัว');
  }
  if (
    basicInfo.familyEvent &&
    basicInfo.familyEvent.indexOf('other') >= 0 &&
    !basicInfo.familyEventMore
  ) {
    errors.push('กรุณาใส่ข้อมูล เหตุการณ์สำคัญของครอบครัว เพิ่มเติม');
  }

  return errors;
}

export function basicInfoNextFn(
  basicInfo: BasicInfoDataState,
  dataDispatch: React.Dispatch<DataAction>,
  dispatch: React.Dispatch<PageAction>,
) {
  const errors = checkValidate(basicInfo);
  if (errors.length > 0) {
    alert(errors.join(',\n'));
    return;
  }

  let name = basicInfo.firstName ? basicInfo.firstName : '';
  if (basicInfo.lastName) {
    name = name + ` ${basicInfo.lastName}`;
  }
  dataDispatch({
    type: 'set',
    name: 'name',
    value: name,
  });

  dataDispatch({
    type: 'set',
    name: 'age',
    value: basicInfo.age ? basicInfo.age : '',
  });

  dataDispatch({
    type: 'set',
    name: 'gender',
    value: basicInfo.gender ? basicInfo.gender : '',
  });
  dispatch({ type: 'next' });
}
