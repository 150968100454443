import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { apiURL } from '../config';

type LoginProps = {
  onLoginSuccess?: () => void;
};

export function Login(props: LoginProps) {
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  return (
    <Form
      style={{
        display: 'flex',
        width: '400px',
        maxWidth: '100%',
        margin: '0 auto',
        flexDirection: 'column',
        padding: '20px',
        border: '1px solid #cccccc',
        boxShadow: '5px 10px;',
      }}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        return axios
          .post(`${apiURL}/login`, {
            username: username,
            password: password,
          })
          .then(res => {
            if (res.data.success) {
              sessionStorage.setItem('userId', res.data.lineId);
              sessionStorage.setItem('name', res.data.name);
              if (props.onLoginSuccess) {
                props.onLoginSuccess();
              }
            } else {
              alert('รหัสผ่าน หรือ ชื่อผู้ใช้ไม่ถูกต้อง');
            }
          });
      }}
    >
      <Form.Group controlId="username">
        <Form.Label>username</Form.Label>
        <Form.Control
          type="text"
          value={username}
          onChange={(evt: any) => {
            setUsername(evt.target.value);
          }}
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          onChange={(evt: any) => {
            setPassword(evt.target.value);
          }}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}
