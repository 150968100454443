import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_2,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QPRGCriteriaKeys, QPRGKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QPRGDefinition = Definition &
  SubCriteriaDefinition<QPRGCriteriaKeys>;

export const qPRGDef: QPRGDefinition = {
  id: 'qprg',
  need: 'ต้องการสวัสดิการทางการศึกษา',
  category: 'ท้อง',
  label: `${PREFIX_2}ท้อง`,
  subCriteriaLabel: 'ลักษณะความเสี่ยง',
  subCriteriaChoices: {
    qprg_1_1: 'เด็กถูกเลือกปฏิบัติ หรือกีดกันไม่ให้เข้าเรียน',
    qprg_1_2: 'ขาดคำแนะนำในการวางแผนชีวิต',
    qprg_1_3: 'อื่นๆ',
  },
  subCriteriaKeys: QPRGKeys,
};

export function copyQPRGDependentValues(source: DataState, target: DataState) {
  if (source.qprg) {
    target.qprg = true;
    QPRGKeys.forEach(k => (target[k] = source[k] || false));
    target.qprg_text = source.qprg_text;
  }
}

export function QPRGDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qprg) {
    return (
      <div>
        {qPRGDef.need} จาก {qPRGDef.category} {qPRGDef.subCriteriaLabel} คือ
        <ul>
          {qPRGDef.subCriteriaKeys.map((k: QPRGCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qPRGDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qprg_text ? <div>คำอธิบายเพิ่มเติม: {data.qprg_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QPRG(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qprg_1_3
    ? notEmpty(data.qprg_text)
    : data.qprg_1_1 || data.qprg_1_2;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qPRGDef.label}</div>
        <SubCriteriaCheckboxes<QPRGCriteriaKeys>
          definition={qPRGDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qprg_text">
            <Form.Control
              type="text"
              value={data.qprg_text || ''}
              isInvalid={data.qprg_1_3 && empty(data.qprg_text)}
              onChange={(evt: any) => set('qprg_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
