import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import GeneralPage from './generalPage';
import TopicPage from './topicPage';
import ConfirmPage from './confirmPage';
import SubTopicPage from './subTopicPage';
import { getPageInitialState, PageAction, pageReducer } from './page';
import { dataReducer, getDefaultDataState } from './data';
import '../App.css';
import { apiURL } from '../config';
import PersonForm, {
  BasicInfoDataState,
  basicInfoNextFn,
  checkValidate,
} from '../components/PersonForm';
import { Button, Col, Container, Row } from 'react-bootstrap';

declare global {
  interface Window {
    uid: string;
    lineName: string;
    lineAvatar: string;
  }
}

type UserInfo = {
  name: string;
  anonymous: boolean;
};

export function BasicInfoForm(
  basicInfo: BasicInfoDataState,
  setBasicInfo: (
    value:
      | ((prevState: BasicInfoDataState) => BasicInfoDataState)
      | BasicInfoDataState,
  ) => void,
  onNext: () => void,
) {
  return (
    <Container>
      <div className="page-title">ข้อมูลเบื้องต้น</div>
      <PersonForm data={basicInfo} setData={setBasicInfo} />
      <Row>
        <Col className="text-right">
          <Button className="next-btn" variant="primary" onClick={onNext}>
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

function Report() {
  const [{ page }, dispatch] = React.useReducer(
    pageReducer,
    getPageInitialState(),
  );

  const [data, dataDispatch] = React.useReducer(
    dataReducer,
    getDefaultDataState(),
  );

  const [message, setMessage] = React.useState('Loading...');
  const [basicInfo, setBasicInfo] = React.useState<BasicInfoDataState>({});

  React.useEffect(() => {
    axios.get(`${apiURL}/users/${window.uid}`).then(res => {
      if (res.data.success) {
        setUserInfo(res.data.data);
      } else {
        setMessage(
          res.data.message || 'เกิดความผิดพลาดในการดึงข้อมูลผู้ใช้งาน',
        );
      }
    });
  }, []);

  const [userInfo, setUserInfo] = React.useState<UserInfo | null>(null);

  if (userInfo !== null) {
    const firstPage = userInfo.anonymous ? (
      <GeneralPage
        data={data}
        dispatch={dataDispatch}
        onNext={() => dispatch({ type: 'next' })}
        geoLocation={false}
      />
    ) : (
      BasicInfoForm(basicInfo, setBasicInfo, () => {
        basicInfoNextFn(basicInfo, dataDispatch, dispatch);
      })
    );

    return (
      <div>
        {page === 'general' ? firstPage : null}
        {page === 'topic' ? (
          <TopicPage
            data={data}
            dispatch={dataDispatch}
            onNext={() => {
              dispatch({ type: 'next' });
              dataDispatch({
                type: 'setNumber',
                name: 'subPageIndex',
                value: 0,
              });
            }}
            onBack={() => dispatch({ type: 'back' })}
          />
        ) : null}
        {page === 'subTopic' ? (
          <SubTopicPage
            data={data}
            dispatch={dataDispatch}
            onNext={() => dispatch({ type: 'next' })}
            onBack={() => dispatch({ type: 'back' })}
          />
        ) : null}
        {page === 'confirm' ? (
          <ConfirmPage
            data={data}
            basicInfo={basicInfo}
            onBack={() => dispatch({ type: 'back' })}
            onSubmit={() => console.log('submit')}
            webapp={false}
          />
        ) : null}
      </div>
    );
  } else {
    return <div>{message}</div>;
  }
}

export default Report;
