import React, { Fragment } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Activity from '../activity';
import { Button, Col, Container, Navbar, Row } from 'react-bootstrap';

export default function Spa() {
  const history = useHistory();
  const userId = sessionStorage.getItem('userId');
  const name = sessionStorage.getItem('name');
  if (!userId) {
    return <Redirect to="/public" />;
  } else {
    return (
      <Fragment>
        <Navbar bg="light" expand={true}>
          <Navbar.Brand href="/">
            <img src="/logo-srs.png" />
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text style={{ paddingRight: 20 }}>
              ผู้ใช้งาน: {name}
            </Navbar.Text>
            <Button
              variant="light"
              onClick={() => {
                sessionStorage.removeItem('userId');
                sessionStorage.removeItem('name');
                history.replace('/');
              }}
            >
              Logout
            </Button>
          </Navbar.Collapse>
        </Navbar>
        <div>
          <Container>
            <Row>
              <Col className="text-right">
                <Button
                  variant="outline-primary"
                  style={{ marginRight: '1rem', marginTop: '1rem' }}
                  onClick={() => {
                    history.push('/report');
                  }}
                >
                  + รายงาน
                </Button>
              </Col>
            </Row>
          </Container>
          <Activity />
        </div>
      </Fragment>
    );
  }
}
