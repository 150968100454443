import * as React from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

type State = {
  isPending: boolean;
  isFulfilled: boolean;
  isRejected: boolean;
};

type Props = {
  onClick: () => Promise<void>;
  text?: string;
  pendingText?: string;
  fulFilledText?: string;
  rejectedText?: string;
  className?: string;
  loadingClass?: string;
  fulFilledClass?: string;
  rejectedClass?: string;
} & ButtonProps;

//https://jsbin.com/hugigok/18/edit?js,output
export default class AsyncButton extends React.Component<Props, State> {
  unmount = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      isPending: false,
      isFulfilled: false,
      isRejected: false,
    };
    this.resetState = this.resetState.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  resetState() {
    this.setState({
      isPending: false,
      isFulfilled: false,
      isRejected: false,
    });
  }

  componentDidMount() {
    this.unmount = false;
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  handleClick() {
    this.setState({
      isPending: true,
    });

    let promise = this.props.onClick();
    if (promise && promise.then) {
      promise
        .then(() => {
          if (!this.unmount) {
            this.setState({
              isPending: false,
              isRejected: false,
              isFulfilled: true,
            });
          }
        })
        .catch(error => {
          if (!this.unmount) {
            this.setState({
              isPending: false,
              isRejected: true,
              isFulfilled: false,
            });
          }
          throw error;
        });
    } else {
      this.resetState();
    }
  }

  render() {
    const { isPending, isFulfilled, isRejected } = this.state;
    const {
      children,
      text,
      pendingText,
      fulFilledText,
      rejectedText,
      className,
      loadingClass,
      fulFilledClass,
      rejectedClass,
      disabled,
    } = this.props;
    const isDisabled = disabled || isPending;
    let buttonText;

    if (isPending) {
      buttonText = pendingText;
    } else if (isFulfilled) {
      buttonText = fulFilledText;
    } else if (isRejected) {
      buttonText = rejectedText;
    }
    buttonText = buttonText || text;

    const btnClasses = classNames(className, {
      [`${loadingClass || 'AsyncButton--loading'}`]: isPending,
      [`${fulFilledClass || 'AsyncButton--fulfilled'}`]: isFulfilled,
      [`${rejectedClass || 'AsyncButton--rejected'}`]: isRejected,
    });

    return (
      <Button
        {...this.props}
        className={btnClasses}
        disabled={isDisabled}
        onClick={() => this.handleClick()}
      >
        {isPending ? (
          <Spinner className="mr-1" animation="border" size="sm" />
        ) : null}
        {children || buttonText}
      </Button>
    );
  }
}
