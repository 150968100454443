import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { copyQSXVDependentValues, QSXVDescribe } from './questions/qSXV';
import { copyQTRFDependentValues, QTRFDescribe } from './questions/qTRF';
import {
  BooleanQuestions,
  DataState,
  getDefaultTopicData,
  TextQuestions,
  TopicQuestion,
} from './data';
import { copyQLABDependentValues, QLABDescribe } from './questions/qLAB';
import { copyQDRGDependentValues, QDRGDescribe } from './questions/qDRG';
import { copyQSEDDependentValues, QSEDDescribe } from './questions/qSED';
import { copyQEDUDependentValues, QEDUDescribe } from './questions/qEDU';
import { copyQNPRDependentValues, QNPRDescribe } from './questions/qNPR';
import { copyQPRGDependentValues, QPRGDescribe } from './questions/qPRG';
import { copyQNATDependentValues, QNATDescribe } from './questions/qNAT';
import { copyQPORDependentValues, QPORDescribe } from './questions/qPOR';
import { copyQLRNDependentValues, QLRNDescribe } from './questions/qLRN';
import { copyQFODDependentValues, QFODDescribe } from './questions/qFOD';
import { copyQHOUDependentValues, QHOUDescribe } from './questions/qHOU';
import { copyQHEADependentValues, QHEADescribe } from './questions/qHEA';
import { copyQEMODependentValues, QEMODescribe } from './questions/qEMO';
import AsyncButton from '../components/AsyncButton';
import axios from 'axios';
import { apiURL } from '../config';
import { copyQBMVDependentValues, QBMVDescribe } from './questions/qBMV';
import { copyQDISDependentValues, QDISDescribe } from './questions/qDIS';
import { BasicInfoDataState } from '../components/PersonForm';
import { Redirect } from 'react-router-dom';

type ReportResponse = {
  success: boolean;
  id: number;
  error: {
    code: string;
    message: string;
  };
};
type SubmitData = TopicQuestion & BooleanQuestions & TextQuestions;

function prepareData(source: DataState): SubmitData {
  const target: SubmitData = getDefaultTopicData();
  [
    copyQSXVDependentValues,
    copyQTRFDependentValues,
    copyQBMVDependentValues,
    copyQLABDependentValues,
    copyQDRGDependentValues,
    copyQSEDDependentValues,
    copyQEDUDependentValues,
    copyQNPRDependentValues,
    copyQPRGDependentValues,
    copyQDISDependentValues,
    copyQNATDependentValues,
    copyQPORDependentValues,
    copyQLRNDependentValues,
    copyQFODDependentValues,
    copyQHOUDependentValues,
    copyQHEADependentValues,
    copyQEMODependentValues,
  ].map(fn => fn(source, target));
  return target;
}

type ConfirmPagePropsType = {
  data: DataState;
  basicInfo: BasicInfoDataState;
  onBack: () => void;
  onSubmit: () => void;
  webapp: boolean;
};
function ConfirmPage(props: ConfirmPagePropsType) {
  const { data, onBack, basicInfo, webapp } = props;
  const [isSubmitted, setSubmitted] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const results = [
    QSXVDescribe,
    QTRFDescribe,
    QBMVDescribe,
    QLABDescribe,
    QDRGDescribe,
    QSEDDescribe,
    QEDUDescribe,
    QNPRDescribe,
    QPRGDescribe,
    QDISDescribe,
    QNATDescribe,
    QPORDescribe,
    QLRNDescribe,
    QFODDescribe,
    QHOUDescribe,
    QHEADescribe,
    QEMODescribe,
  ].map(fn => fn({ data }));
  const onSubmit: () => Promise<void> = () => {
    setInProgress(true);
    const title = `ชื่อ: ${data.name} อายุ: ${data.age} เพศ: ${
      data.gender === 'male' ? 'ชาย' : 'หญิง'
    }`;
    const txt = ReactDOMServer.renderToString(<>{results}</>);
    console.log(title, txt);
    const htmlMessage = `${title} ${txt}`;
    let { uid } = window;
    if (!uid) {
      const tmp = sessionStorage.getItem('userId');
      if (tmp) {
        uid = tmp;
      } else {
        uid = 'anonymous';
      }
    }
    console.log('uid', uid);
    const payload = {
      lineId: uid,
      renderData: htmlMessage,
      name: data.name,
      age: data.age,
      gender: data.gender,
      location: data.location,
      data: prepareData(data),
      person: basicInfo,
    };
    console.log('payload', payload);
    return axios
      .request<ReportResponse>({
        url: `${apiURL}/report`,
        method: 'post',
        timeout: 15000,
        data: payload,
      })
      .then(res => {
        setSubmitted(true);
        try {
          if (!webapp && liff) {
            return liff
              .sendMessages([
                {
                  type: 'text',
                  text: `รายงาน #${res.data.id}`,
                },
              ])
              .then(() => {
                return liff.closeWindow();
              })
              .catch(e => {
                console.log(e);
              });
          }
        } catch (e) {
          // กรณี liff ไม่มีค่า จะตก error นี้
        }
      });
  };

  return (
    <Container>
      {isSubmitted ? (
        webapp ? (
          <Redirect to="/" />
        ) : (
          <div>ขอบคุณสำหรับการรายงาน</div>
        )
      ) : (
        <>
          <div className="page-title">ยืนยันข้อมูล</div>
          <Row>
            <Col className="mb-3 mt-3">
              ชื่อ: {data.name} อายุ: {data.age} เพศ:
              {data.gender === 'male' ? 'ชาย' : 'หญิง'}
            </Col>
          </Row>
          {results
            .filter(r => r !== null)
            .map((r, idx) => (
              <div className="question-group mb-3" key={idx}>
                {r}
              </div>
            ))}
          <Row>
            <Col className="text-left">
              <Button className="btn-light" onClick={onBack}>
                ย้อนกลับ
              </Button>
            </Col>
            <Col className="text-right">
              <AsyncButton
                variant="primary"
                disabled={inProgress}
                onClick={onSubmit}
              >
                ส่งข้อมูล
              </AsyncButton>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ConfirmPage;
