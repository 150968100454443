import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_3,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QFODCriteriaKeys, QFODKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QFODDefinition = Definition &
  SubCriteriaDefinition<QFODCriteriaKeys>;

export const qFODDef: QFODDefinition = {
  id: 'qfod',
  need: 'ต้องการความช่วยเหลือด้านอื่นๆ',
  category: 'อาหารและโภชนาการที่เหมาะสม',
  label: `${PREFIX_3}อาหารและโภชนาการที่เหมาะสม`,
  subCriteriaLabel: 'ลักษณะความเสี่ยง',
  subCriteriaChoices: {
    qfod_1_1: 'เด็กมีอาหารไม่เพียงพอ กินอาหารไม่เพียงพอ',
    qfod_1_2: 'เจริญเติบโตต่ำกว่าเกณฑ์',
    qfod_1_3: 'มีภาวะโรคอ้วน',
    qfod_1_4: 'อื่นๆ',
  },
  subCriteriaKeys: QFODKeys,
};

export function copyQFODDependentValues(source: DataState, target: DataState) {
  if (source.qfod) {
    target.qfod = true;
    QFODKeys.forEach(k => (target[k] = source[k] || false));
    target.qfod_text = source.qfod_text;
  }
}

export function QFODDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qfod) {
    return (
      <div>
        {qFODDef.need} กรณี {qFODDef.category} {qFODDef.subCriteriaLabel} คือ
        <ul>
          {qFODDef.subCriteriaKeys.map((k: QFODCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qFODDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qfod_text ? <div>คำอธิบายเพิ่มเติม: {data.qfod_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QFOD(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qfod_1_4
    ? notEmpty(data.qfod_text)
    : data.qfod_1_1 || data.qfod_1_2 || data.qfod_1_3;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qFODDef.label}</div>
        <SubCriteriaCheckboxes<QFODCriteriaKeys>
          definition={qFODDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qfod_text">
            <Form.Control
              type="text"
              value={data.qfod_text || ''}
              isInvalid={data.qfod_1_4 && empty(data.qfod_text)}
              onChange={(evt: any) => set('qfod_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
