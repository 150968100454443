import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_2,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QSEDCriteriaKeys, QSEDKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QSEDDefinition = Definition &
  SubCriteriaDefinition<QSEDCriteriaKeys>;

export const qSEDDef: QSEDDefinition = {
  id: 'qsed',
  need: 'ถูกละเมิดหรือไม่มีคนคุ้มครอง',
  category: 'ถูกล่อลวง ',
  label: `${PREFIX_2}ถูกล่อลวง`,
  subCriteriaLabel: 'ลักษณะความเสี่ยง',
  subCriteriaChoices: {
    qsed_1_1: 'อยู่กับบุคคลไม่น่าไว้วางใจ',
    qsed_1_2: 'อยู่ลำพังในสถานที่ที่ไม่เหมาะ ',
    qsed_1_3: 'อื่นๆ',
  },
  subCriteriaKeys: QSEDKeys,
};

export function copyQSEDDependentValues(source: DataState, target: DataState) {
  if (source.qsed) {
    target.qsed = true;
    QSEDKeys.forEach(k => (target[k] = source[k] || false));
    target.qsed_text = source.qsed_text;
  }
}

export function QSEDDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qsed) {
    return (
      <div>
        {qSEDDef.need} จาก {qSEDDef.category} {qSEDDef.subCriteriaLabel} คือ
        <ul>
          {qSEDDef.subCriteriaKeys.map((k: QSEDCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qSEDDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qsed_text ? <div>คำอธิบายเพิ่มเติม: {data.qsed_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QSED(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qsed_1_3
    ? notEmpty(data.qsed_text)
    : data.qsed_1_1 || data.qsed_1_2;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qSEDDef.label}</div>
        <SubCriteriaCheckboxes<QSEDCriteriaKeys>
          definition={qSEDDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qsed_text">
            <Form.Control
              type="text"
              value={data.qsed_text || ''}
              isInvalid={data.qsed_1_3 && empty(data.qsed_text)}
              onChange={(evt: any) => set('qsed_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
