import React from 'react';
import axios from 'axios';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import '../App.css';
import { formatDate, formatDateTime } from '../components/DatePickerField';
import { GetPersonResponse } from './interface';
import { apiURL } from '../config';
import PersonForm, {
  BasicInfoDataState,
  checkValidate,
} from '../components/PersonForm';

export type BasicInfoProps = {
  data: BasicInfoDataState;
  setData: (b: BasicInfoDataState) => void;
  onSave: (data: BasicInfoDataState) => void;
  reportId: number;
};

export function BasicInfoForm(props: BasicInfoProps) {
  const { data, setData, onSave, reportId } = props;
  const [show, setShow] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const onSubmit = () => {
    const values = {
      ...data,
      birthDate: formatDate(data.birthDate as Date),
    };
    return axios
      .request<GetPersonResponse>({
        url: `${apiURL}/managedReports/${reportId}/person`,
        method: 'post',
        timeout: 15000,
        data: values,
      })
      .then(({ data }) => {
        if (data.success && data.data) {
          const info = data.data;
          if (info.birthDate) {
            info.birthDate = new Date(info.birthDate);
          }
          if (info.createdDate) {
            info.createdDate = new Date(info.createdDate);
          }
          if (info.updatedDate) {
            info.updatedDate = new Date(info.updatedDate);
          }
          console.log('info----', info);
          onSave(info);
          setShow(false);
        } else {
          setError('ไม่สามารถบันทึกข้อมูลได้: ' + data.message);
        }
      })
      .catch(e => {
        console.log(e);
        setError(e);
      });
  };

  return (
    <>
      {error !== null ? <Alert variant="danger">{error}</Alert> : null}

      <div className="d-flex align-items-baseline mb-3">
        <div>
          <div className="detail-title">ข้อมูลทั่วไปจากการสัมภาษณ์</div>
        </div>
        <div className="ml-auto">
          <Button
            className="rounded-circle"
            variant="secondary"
            onClick={() => setShow(!show)}
          >
            {show ? <span>▲</span> : <span>▼</span>}
          </Button>
        </div>
      </div>
      {show ? (
        <div className="border-bottom">
          <PersonForm
            data={data}
            setData={setData}
            onSubmit={() => {
              const errors = checkValidate(data);
              if (errors.length > 0) {
                alert(errors.join(',\n'));
                return new Promise((resolve, reject) => resolve());
              } else {
                return onSubmit();
              }
            }}
          />
        </div>
      ) : (
        <div className="border-bottom py-3">
          <Row>
            <Col>
              {!data.firstName ? (
                <Alert variant="warning" onClick={() => setShow(true)}>
                  <span className="detail-subtitle">
                    ยังไม่ได้บันทึก กดเพื่อเริ่มต้นบันทึก
                  </span>
                </Alert>
              ) : (
                <Alert
                  variant="success"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <span className="detail-subtitle">
                    {data.updatedDate
                      ? `แก้ไขครั้งสุดท้าย ${formatDateTime(
                          data.updatedDate,
                        )} กดเพื่อดูข้อมูล`
                      : null}
                  </span>
                </Alert>
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
