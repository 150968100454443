import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_3,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QHEACriteriaKeys, QHEAKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QHEADefinition = Definition &
  SubCriteriaDefinition<QHEACriteriaKeys>;

export const qHEADef: QHEADefinition = {
  id: 'qhea',
  need: 'ต้องการความช่วยเหลือด้านอื่นๆ',
  category: 'บริการด้านสุขภาพ',
  label: `${PREFIX_3}บริการด้านสุขภาพ`,
  subCriteriaLabel: 'ลักษณะ',
  subCriteriaChoices: {
    qhea_1_1:
      'เข้าไม่ถึงบริการด้านสุขภาพ เช่น เดินทางไม่สะดวก ไม่มีบัตร ต้องรับยาประจำแต่ไม่ได้ เป็นต้น',
    qhea_1_2: 'มีโรคเรื้อรัง เจ็บป่วยบ่อย และยังไม่ได้รับการรักษา ',
    qhea_1_3: 'ได้รับการรักษาที่ไม่เหมาะสมเมื่อเจ็บป่วย',
    qhea_1_4: 'อื่นๆ',
  },
  subCriteriaKeys: QHEAKeys,
};

export function copyQHEADependentValues(source: DataState, target: DataState) {
  if (source.qhea) {
    target.qhea = true;
    QHEAKeys.forEach(k => (target[k] = source[k] || false));
    target.qhea_text = source.qhea_text;
  }
}

export function QHEADescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qhea) {
    return (
      <div>
        {qHEADef.need} กรณี {qHEADef.category} {qHEADef.subCriteriaLabel} คือ
        <ul>
          {qHEADef.subCriteriaKeys.map((k: QHEACriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qHEADef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qhea_text ? <div>คำอธิบายเพิ่มเติม: {data.qhea_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QHEA(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qhea_1_4
    ? notEmpty(data.qhea_text)
    : data.qhea_1_1 || data.qhea_1_2 || data.qhea_1_3;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qHEADef.label}</div>
        <SubCriteriaCheckboxes<QHEACriteriaKeys>
          definition={qHEADef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qhea_text">
            <Form.Control
              type="text"
              value={data.qhea_text || ''}
              isInvalid={data.qhea_1_4 && empty(data.qhea_text)}
              onChange={(evt: any) => set('qhea_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
