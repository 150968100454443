import { Form } from 'react-bootstrap';
import React from 'react';

export type CheckBoxType = {
  id: string;
  label: string;
  value: boolean | undefined;
  setBoolean: (name: string, value: boolean) => void;
};
export function CheckBox(props: CheckBoxType) {
  const { id, label, value, setBoolean } = props;
  return (
    <div className="mb-3">
      <Form.Check
        custom
        type="checkbox"
        id={id}
        checked={value || false}
        label={label}
        onChange={(evt: any) => {
          setBoolean(id, evt.target.checked);
        }}
      />
    </div>
  );
}
