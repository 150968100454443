import React from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { formatDateTime } from '../components/DatePickerField';
import AsyncButton from '../components/AsyncButton';
import axios from 'axios';
import { GetPlanResponse } from './interface';
import { apiURL } from '../config';
import { TopicQuestion } from '../report/data';
import { Case } from './state';

function isProtectCase(reportData: TopicQuestion) {
  return (
    reportData &&
    (reportData['qsxv'] ||
      reportData['qtrf'] ||
      reportData['qbmv'] ||
      reportData['qlab'] ||
      reportData['qdrg'] ||
      reportData['qsed'])
  );
}

function isEducationCase(reportData: TopicQuestion) {
  return (
    reportData &&
    (reportData['qedu'] ||
      reportData['qnpr'] ||
      reportData['qprg'] ||
      reportData['qdis'] ||
      reportData['qnat'] ||
      reportData['qpor'] ||
      reportData['qlrn'])
  );
}

function isOtherCase(reportData: TopicQuestion) {
  return (
    reportData &&
    (reportData['qfod'] ||
      reportData['qhou'] ||
      reportData['qhea'] ||
      reportData['qemo'])
  );
}

export type PlanState = {
  id?: number;
  helpPlanProtect?: string;
  followPlanProtect?: string;
  helpPlanEducation?: string;
  followPlanEducation?: string;
  helpPlanOther?: string;
  followPlanOther?: string;
  createdDate?: Date;
  updatedDate?: Date;
};

type detailPropsType = {
  title: string;
  data: PlanState;
  setValue: (key: string, value: string) => void;
  helpKey: 'helpPlanProtect' | 'helpPlanEducation' | 'helpPlanOther';
  followKey: 'followPlanProtect' | 'followPlanEducation' | 'followPlanOther';
};

function Detail(props: detailPropsType) {
  const { title, data, setValue, helpKey, followKey } = props;
  return (
    <div className="question-group mb-3">
      <div
        className="question-group-title"
        style={{ fontWeight: 600, color: '#555555' }}
      >
        {title}
      </div>
      <Form.Row>
        <Form.Group as={Col} md={6} controlId={helpKey}>
          <Form.Label>แนวทางการช่วยเหลือ</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={data && data[helpKey] ? data[helpKey] : ''}
            onChange={(evt: any) => setValue(helpKey, evt.target.value)}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md={6} controlId={followKey}>
          <Form.Label>แนวทางการติดตามและประเมินผล</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={data && data[followKey] ? data[followKey] : ''}
            onChange={(evt: any) => setValue(followKey, evt.target.value)}
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
}

type PlanProps = {
  reportId: number;
  setData: (data: PlanState) => void;
  data: PlanState;
  report: Case | undefined;
};

function Plan(props: PlanProps) {
  const { data, setData, reportId, report } = props;
  const [show, setShow] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const setValue = (key: string, value: string | number | Date) => {
    console.log('k/v', key, value);
    setData({
      ...data,
      [key]: value,
    });
  };

  const onSubmit = () => {
    return axios
      .request<GetPlanResponse>({
        url: `${apiURL}/managedReports/${reportId}/plan`,
        method: 'post',
        timeout: 15000,
        data: {
          id: data.id,
          helpPlanProtect: data.helpPlanProtect,
          followPlanProtect: data.followPlanProtect,
          helpPlanEducation: data.helpPlanEducation,
          followPlanEducation: data.followPlanEducation,
          helpPlanOther: data.helpPlanOther,
          followPlanOther: data.followPlanOther,
        },
      })
      .then(({ data }) => {
        if (data.success && data.data) {
          const p = data.data;
          if (p.createdDate) {
            p.createdDate = new Date(p.createdDate);
          }
          if (p.updatedDate) {
            p.updatedDate = new Date(p.updatedDate);
          }
          setData(p);
          setShow(false);
        } else {
          setError('ไม่สามารถบันทึกข้อมูลได้');
        }
      });
  };
  console.log('report', report);
  return (
    <>
      {error !== null ? <Alert variant="danger">{error}</Alert> : null}
      <div className="d-flex align-items-baseline mb-3 mt-3">
        <div>
          <div className="detail-title">แนวทางการให้ความช่วยเหลือ</div>
        </div>
        <div className="ml-auto">
          <Button
            className="rounded-circle"
            variant="secondary"
            onClick={() => setShow(!show)}
          >
            {show ? <span>▲</span> : <span>▼</span>}
          </Button>
        </div>
      </div>
      {show ? (
        <Form className="pb-3">
          {report && isProtectCase(report.data) ? (
            <Detail
              data={data}
              followKey="followPlanProtect"
              helpKey="helpPlanProtect"
              setValue={setValue}
              title="ความช่วยเหลือด้านการถูกละเมิดหรือไม่มีคนคุ้มครอง"
            />
          ) : null}

          {report && isEducationCase(report.data) ? (
            <Detail
              data={data}
              followKey="followPlanEducation"
              helpKey="helpPlanEducation"
              setValue={setValue}
              title="ความช่วยเหลือด้านสวัสดิการการศึกษา"
            />
          ) : null}
          {report && isOtherCase(report.data) ? (
            <Detail
              data={data}
              followKey="followPlanOther"
              helpKey="helpPlanOther"
              setValue={setValue}
              title="ความช่วยเหลือด้านอื่นๆ"
            />
          ) : null}

          {onSubmit ? (
            <Row>
              <Col className="text-right">
                <AsyncButton variant="primary" onClick={onSubmit}>
                  บันทึก
                </AsyncButton>
              </Col>
            </Row>
          ) : null}
        </Form>
      ) : (
        <div className="border-bottom py-3">
          <Row>
            <Col>
              {data.id === undefined ? (
                <Alert variant="warning" onClick={() => setShow(true)}>
                  <span className="detail-subtitle">
                    ยังไม่ได้บันทึก กดเพื่อเริ่มต้นบันทึก
                  </span>
                </Alert>
              ) : (
                <Alert
                  variant="success"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <span className="detail-subtitle">
                    {data && data.updatedDate
                      ? `แก้ไขครั้งสุดท้าย ${formatDateTime(
                          data.updatedDate,
                        )} กดเพื่อดูข้อมูล`
                      : null}
                  </span>
                </Alert>
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default Plan;
