import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_1,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QSXVCriteriaKeys, QSXVKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QSXVDefinition = Definition &
  SubCriteriaDefinition<QSXVCriteriaKeys>;

export const qSXVDef: QSXVDefinition = {
  id: 'qsed',
  need: 'ถูกละเมิดหรือไม่มีคนคุ้มครอง',
  category: 'ความรุนแรงทางเพศ',
  label: `${PREFIX_1}ความรุนแรงทางเพศ`,
  subCriteriaLabel: 'ลักษณะความเสี่ยง',
  subCriteriaChoices: {
    qsxv_1_1: 'ถูกล่วงละเมิดทางเพศทางกาย',
    qsxv_1_2: 'ถูกล่วงละเมิดทางวาจา',
    qsxv_1_3: 'อื่นๆ',
  },
  subCriteriaKeys: QSXVKeys,
  // causeByLabel: 'ถูกกระทำโดย',
  // causeByChoices: {
  //   q1_c_1: 'พ่อหรือแม่',
  //   q1_c_2: 'เพื่อน',
  //   q1_c_3: 'ญาติ',
  //   q1_c_4: 'อื่นๆ',
  // },
  // causeByKeys: ['q1_c_1', 'q1_c_2', 'q1_c_3', 'q1_c_4'],
};

export function copyQSXVDependentValues(source: DataState, target: DataState) {
  if (source.qsxv) {
    target.qsxv = true;
    QSXVKeys.forEach(k => (target[k] = source[k] || false));
    target.qsxv_text = source.qsxv_text;
  }
}

export function QSXVDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qsxv) {
    return (
      <div>
        {qSXVDef.need} จาก {qSXVDef.category} {qSXVDef.subCriteriaLabel} คือ
        <ul>
          {qSXVDef.subCriteriaKeys.map((k: QSXVCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qSXVDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qsxv_text ? <div>คำอธิบายเพิ่มเติม: {data.qsxv_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QSXV(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qsxv_1_3
    ? notEmpty(data.qsxv_text)
    : data.qsxv_1_1 || data.qsxv_1_2;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qSXVDef.label}</div>
        <SubCriteriaCheckboxes<QSXVCriteriaKeys>
          definition={qSXVDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qsxv_text">
            <Form.Control
              type="text"
              value={data.qsxv_text || ''}
              isInvalid={data.qsxv_1_3 && empty(data.qsxv_text)}
              onChange={(evt: any) => set('qsxv_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={() => {
              if (data.qsxv_1_3 && empty(data.qsxv_text)) {
                alert('กรุณาใส่คำอธิบายเพิ่มเติม');
              } else {
                onNext();
              }
            }}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
