import React from 'react';
import {
  Image,
  Overlay,
  Popover,
  InputGroup,
  Button,
  Form,
  Col,
} from 'react-bootstrap';
import calendarIcon from './calendar.png';

const initYear = new Date().getFullYear();
const now = new Date();
const MONTHS = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฏาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];

// all years for selection
const yearSelection = new Array<number>();
for (let i = initYear - 18; i <= initYear; i++) {
  yearSelection.push(i);
}

// reserve spaces for 6 weeks
const dateSelection = [...Array<number>(42)];

const css: any = {
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  th: {
    width: 'calc(100% / 7)',
    background: '#efefef',
    textAlign: 'center',
    padding: '.3rem',
  },
  td: (match: boolean) => ({
    textAlign: 'center',
    padding: '.3rem',
    backgroundColor: match ? 'lightblue' : 'none',
  }),
};

export function formatDateTh(d: Date | null, short: boolean = false): string {
  if (!d) return '';
  return short
    ? `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear() + 543}`
    : `${d.getDate()} ${MONTHS[d.getMonth()]} ${d.getFullYear() + 543}`;
}

function pad(n: number, width: number) {
  let tmp = 10 ** width + n;
  return tmp.toString().substring(1);
}

export function formatDate(date: Date | null): string {
  if (!date) return '';
  const y = date.getFullYear();
  const m = pad(date.getMonth() + 1, 2);
  const d = pad(date.getDate(), 2);
  return `${y}-${m}-${d}`;
}

export function formatDateTime(date: Date | null): string {
  if (!date) return '';
  const y = date.getFullYear();
  const m = pad(date.getMonth() + 1, 2);
  const d = pad(date.getDate(), 2);
  const hh = pad(date.getHours(), 2);
  const mm = pad(date.getMinutes(), 2);
  return `${y}-${m}-${d} ${hh}:${mm}:00`;
}

type DateGridProps = {
  data: number[];
  month: number;
  year: number;
  target: Date | null;
  onChange?: (d: Date) => void;
};

function DateGrid(props: DateGridProps) {
  const weeks = [...Array(6)];
  const [dates, setDates] = React.useState(props.data);

  React.useEffect(() => {
    setDates(props.data);
  }, [props.data, props.target]);

  const Td = (
    key: string,
    d: number,
    m: number,
    y: number,
    target: Date | null,
  ) => {
    const date = new Date();
    date.setDate(d);
    date.setMonth(m);
    date.setFullYear(y);

    const match =
      target &&
      target.getDate() === d &&
      target.getMonth() === m &&
      target.getFullYear() === y;

    return (
      <td style={css.td(match)} key={key}>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => props.onChange && props.onChange(date)}
        >
          {d ? d : ''}
        </span>
      </td>
    );
  };

  return (
    <table style={css.table}>
      <thead>
        <tr>
          <th style={css.th}>อา</th>
          <th style={css.th}>จ</th>
          <th style={css.th}>อ</th>
          <th style={css.th}>พ</th>
          <th style={css.th}>พฤ</th>
          <th style={css.th}>ศ</th>
          <th style={css.th}>ส</th>
        </tr>
      </thead>
      <tbody>
        {weeks.map((_, i) => (
          <tr key={props.year + 'm' + props.month + 'w' + i}>
            {dates.slice(i * 7, i * 7 + 7).map((d, j) => {
              return Td(
                props.year + 'm' + props.month + 'w' + i + ':' + j + ':' + d,
                dates[i * 7 + j],
                props.month,
                props.year,
                props.target,
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

type DatePickerFieldProps = {
  value?: Date | null;
  allowEmpty?: boolean;
  onChange?: (d: Date) => void;
};

export default function DatePickerField(props: DatePickerFieldProps) {
  let defaultDate = null;
  if (props.allowEmpty) {
    if (props.value) {
      defaultDate = props.value;
    }
  } else {
    if (props.value) {
      defaultDate = props.value;
    } else {
      defaultDate = now;
      // trigger changed value when use default date
      props.onChange && props.onChange(now);
    }
  }

  // show/hide popover
  const [show, setShow] = React.useState(false);
  // popover on target element
  const [target, setTarget] = React.useState<any>(null);
  // date input, default to now
  const [inputDate, setInputDate] = React.useState<Date | null>(defaultDate);
  // current calendar month
  const [month, setMonth] = React.useState(now.getMonth());
  // current calendar year
  const [year, setYear] = React.useState(now.getFullYear());
  // array of dates in that month/year
  const [dates, setDates] = React.useState(dateSelection);
  // use for overlay
  const ref = React.useRef<any>(null);

  const handleClick = () => {
    setShow(!show);
    setTarget(ref.current);
  };

  React.useEffect(() => {
    let d = inputDate; // State starting with now or null or user date
    if (d) {
      setMonth(d.getMonth());
      setYear(d.getFullYear());
    } else {
      setMonth(now.getMonth());
      setYear(now.getFullYear());
    }
    console.log('date input', d);
  }, [inputDate]);

  React.useEffect(() => {
    console.log('m/y', month, year);
    arrangeDates(month, year);
  }, [month, year]);

  //
  const arrangeDates = (m: number, y: number) => {
    const dates = new Array<number>(dateSelection.length);
    const beginningOfMonth = new Date();
    beginningOfMonth.setDate(1);
    beginningOfMonth.setMonth(m);
    beginningOfMonth.setFullYear(y);

    let dayCount = 0;
    const startingIndex = beginningOfMonth.getDay();
    const numOfDaysInMonth = [
      31,
      y % 4 === 0 ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];

    dateSelection.forEach((it, idx) => {
      // reset
      dates[idx] = 0;
      if (idx >= startingIndex) {
        dayCount++;
        if (dayCount <= numOfDaysInMonth[m]) {
          dates[idx] = dayCount;
        }
      }
    });
    setDates(dates);
    console.log('dates in month', dates);
  };

  const onChangeMonth = (evt: any) => {
    setMonth(parseInt(evt.target.value));
  };

  const onChangeYear = (evt: any) => {
    setYear(parseInt(evt.target.value));
  };

  const onChangeInputDate = (date: Date) => {
    const d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const d2 =
      inputDate &&
      new Date(
        inputDate.getFullYear(),
        inputDate.getMonth(),
        inputDate.getDate(),
      );
    if (!d2 || d1.getTime() !== d2.getTime()) {
      console.log(d1, d2);
      setInputDate(date);
      setShow(false);
      props.onChange && props.onChange(date);
    }
  };

  return (
    <InputGroup className="mb-3" ref={ref}>
      <Form.Control
        placeholder="เลือกวัน"
        aria-label="เลือกวัน"
        readOnly
        type="text"
        value={inputDate ? formatDateTh(inputDate) : ''}
        onClick={handleClick}
      />
      <InputGroup.Append>
        <Button variant="outline-secondary" onClick={handleClick}>
          <Image src={calendarIcon} fluid style={{ width: '20px' }} />
        </Button>
      </InputGroup.Append>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref.current}
      >
        <Popover id="popover-contained">
          <Popover.Title as="div">
            <Form>
              <Form.Row>
                <Col xs={5}>
                  {/* year selection */}
                  <Form.Control
                    as="select"
                    size="sm"
                    defaultValue={year.toString()}
                    onChange={onChangeYear}
                  >
                    {yearSelection.map(y => (
                      <option key={y} value={y}>
                        {y + 543}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col xs={5}>
                  {/* month selection */}
                  <Form.Control
                    as="select"
                    size="sm"
                    defaultValue={month.toString()}
                    onChange={onChangeMonth}
                  >
                    {MONTHS.map((m, i) => (
                      <option key={i} value={i}>
                        {m}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col xs={2}>
                  {/* close button */}
                  <Button variant="link" onClick={handleClick}>
                    ✖︎
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Popover.Title>
          <Popover.Content>
            {/* dates grid */}
            <DateGrid
              data={dates}
              month={month}
              year={year}
              target={inputDate}
              onChange={onChangeInputDate}
            />
          </Popover.Content>
        </Popover>
      </Overlay>
    </InputGroup>
  );
}
