export type PageState = {
  page: string;
};

export type PageAction = { type: 'next' } | { type: 'back' };

export type PagePatch = {
  actionMap: { [key: string]: string };
};

const pageDirectionTbl: { [key: string]: PagePatch } = {
  general: {
    actionMap: {
      next: 'topic',
      back: 'general',
    },
  },
  topic: {
    actionMap: {
      next: 'subTopic',
      back: 'general',
    },
  },
  subTopic: {
    actionMap: {
      next: 'confirm',
      back: 'topic',
    },
  },
  confirm: {
    actionMap: {
      next: 'confirm',
      back: 'subTopic',
    },
  },
};

/*
 * general <-> topic <-> confirm
 */
export function pageReducer(state: PageState, action: PageAction) {
  const patch = pageDirectionTbl[state.page];
  const nextPage = patch.actionMap[action.type];
  return {
    ...state,
    page: nextPage,
  };
}

export function getPageInitialState() {
  return {
    page: 'general',
  };
}
