import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_3,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QEMOCriteriaKeys, QEMOKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QEMODefinition = Definition &
  SubCriteriaDefinition<QEMOCriteriaKeys>;

export const qEMODef: QEMODefinition = {
  id: 'qemo',
  need: 'ต้องการความช่วยเหลือด้านอื่นๆ',
  category: 'การดูแลด้านอารมณ์ จิตใจ สังคม',
  label: `${PREFIX_3}การดูแลด้านอารมณ์ จิตใจ สังคม`,
  subCriteriaLabel: 'ลักษณะ',
  subCriteriaChoices: {
    qemo_1_1: 'มีภาวะซึมเศร้า เก็บกด',
    qemo_1_2: 'ควบคุมอารมณ์ไม่ได้ อารมณ์แปรปรวน',
    qemo_1_3: 'เข้ากับเพื่อนไม่ได้ แยกตัว',
    qemo_1_4: 'กวนเมือง เกเร',
    qemo_1_5: 'ติดเกม',
    qemo_1_6: 'ขาดผู้ช่วยชี้แนะชีวิต',
    qemo_1_7: 'ยังไม่ได้หาหมอ',
    qemo_1_8: 'อื่นๆ',
  },
  subCriteriaKeys: QEMOKeys,
};

export function copyQEMODependentValues(source: DataState, target: DataState) {
  if (source.qemo) {
    target.qemo = true;
    QEMOKeys.forEach(k => (target[k] = source[k] || false));
    target.qemo_text = source.qemo_text;
  }
}

export function QEMODescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qemo) {
    return (
      <div>
        {qEMODef.need} กรณี {qEMODef.category} {qEMODef.subCriteriaLabel} คือ
        <ul>
          {qEMODef.subCriteriaKeys.map((k: QEMOCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qEMODef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qemo_text ? <div>คำอธิบายเพิ่มเติม: {data.qemo_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QEMO(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qemo_1_8
    ? notEmpty(data.qemo_text)
    : data.qemo_1_1 ||
      data.qemo_1_2 ||
      data.qemo_1_3 ||
      data.qemo_1_4 ||
      data.qemo_1_5 ||
      data.qemo_1_6 ||
      data.qemo_1_7;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qEMODef.label}</div>
        <SubCriteriaCheckboxes<QEMOCriteriaKeys>
          definition={qEMODef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qemo_text">
            <Form.Control
              type="text"
              value={data.qemo_text || ''}
              isInvalid={data.qemo_1_8 && empty(data.qemo_text)}
              onChange={(evt: any) => set('qemo_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
