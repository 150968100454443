import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_1,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QLABCriteriaKeys, QLABKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QLABDefinition = Definition &
  SubCriteriaDefinition<QLABCriteriaKeys>;

export const qLABDef: QLABDefinition = {
  id: 'qlab',
  need: 'ถูกละเมิดหรือไม่มีคนคุ้มครอง',
  category: 'การใช้แรงงานเด็ก',
  label: `${PREFIX_1}การใช้แรงงานเด็ก`,
  subCriteriaLabel: 'ลักษณะความเสี่ยง',
  subCriteriaChoices: {
    qlab_1_1: 'ถูกใช้แรงงานเกินกำลังหรือเวลาที่กำหนด ',
    qlab_1_2: 'ใช้ทำงานที่ไม่เหมาะสม',
    qlab_1_3: 'ใช้ทำงานในสถานที่ไม่เหมาะสม',
    qlab_1_4: 'อื่นๆ',
  },
  subCriteriaKeys: QLABKeys,
};

export function copyQLABDependentValues(source: DataState, target: DataState) {
  if (source.qlab) {
    target.qlab = true;
    QLABKeys.forEach(k => (target[k] = source[k] || false));
    target.qlab_text = source.qlab_text;
  }
}

export function QLABDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qlab) {
    return (
      <div>
        {qLABDef.need} จาก {qLABDef.category} {qLABDef.subCriteriaLabel} คือ
        <ul>
          {qLABDef.subCriteriaKeys.map((k: QLABCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qLABDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qlab_text ? <div>คำอธิบายเพิ่มเติม: {data.qlab_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QLAB(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qlab_1_4
    ? notEmpty(data.qlab_text)
    : data.qlab_1_1 || data.qlab_1_2 || data.qlab_1_3;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qLABDef.label}</div>
        <SubCriteriaCheckboxes<QLABCriteriaKeys>
          definition={qLABDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qlab_text">
            <Form.Control
              type="text"
              value={data.qlab_text || ''}
              isInvalid={data.qlab_1_4 && empty(data.qlab_text)}
              onChange={(evt: any) => set('qlab_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
