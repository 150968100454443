import React from 'react';
import { Case } from './state';
import { Button, Card, Container } from 'react-bootstrap';
import { formatDateTh } from '../components/DatePickerField';

type ListListProps = {
  cases: Case[];
  onSelect: (id: number) => void;
};

export function LineList(props: ListListProps) {
  const items = props.cases.map(item => {
    console.log(item);
    return (
      <Card style={{ marginBottom: '10px' }} key={item.id}>
        <Card.Body>
          <Card.Title>{formatDateTh(item.date)}</Card.Title>
          <Card.Text>
            <span
              className="detail-desc"
              dangerouslySetInnerHTML={{
                __html: item.description || '',
              }}
            />
          </Card.Text>
          <Button
            variant="primary"
            onClick={() => {
              props.onSelect(item.id);
            }}
          >
            จัดการ
          </Button>
        </Card.Body>
      </Card>
    );
  });
  return <Container style={{ marginTop: '10px' }}>{items}</Container>;
}
