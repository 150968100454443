import { BooleanQuestions, DataState } from '../data';
import { CheckBox } from '../components';
import React from 'react';

export type QProps = {
  data: DataState;
  set: (name: string, value: string) => void;
  setBoolean: (name: string, value: boolean) => void;
  onNext: () => void;
  onBack: () => void;
};

export type Definition = {
  id: string;
  need: string;
  category: string;
  label: string;
};

export type SubCriteriaDefinition<T extends string> = {
  subCriteriaChoices: Record<T, string>;
  subCriteriaLabel: string;
  subCriteriaKeys: readonly T[];
};
export type CauseByDefinition<T extends string> = {
  causeByLabel: string;
  causeByKeys: T[];
  causeByChoices: Record<T, string>;
};

export class SubCriteriaCheckboxes<
  K extends keyof BooleanQuestions
> extends React.Component<
  {
    definition: SubCriteriaDefinition<K>;
    data: DataState;
    setBoolean: (name: string, value: boolean) => void;
  },
  {}
> {
  render() {
    const { definition, data, setBoolean } = this.props;
    return (
      <>
        <div className="question-group-subtitle">
          {definition.subCriteriaLabel}
        </div>
        <div className="choices">
          <div className="mb-3" />

          {definition.subCriteriaKeys.map((key: K) => {
            return (
              <CheckBox
                key={key}
                id={key}
                label={definition.subCriteriaChoices[key]}
                value={data[key]}
                setBoolean={setBoolean}
              />
            );
          })}
        </div>
      </>
    );
  }
}

export class CauseByCheckboxes<
  K extends keyof BooleanQuestions
> extends React.Component<
  {
    definition: CauseByDefinition<K>;
    data: DataState;
    setBoolean: (name: string, value: boolean) => void;
  },
  {}
> {
  render() {
    const { definition, data, setBoolean } = this.props;
    return (
      <>
        <div className="question-group-subtitle">{definition.causeByLabel}</div>
        <div className="choices">
          {definition.causeByKeys.map((key: K) => (
            <CheckBox
              key={key}
              id={key}
              label={definition.causeByChoices[key]}
              value={data[key]}
              setBoolean={setBoolean}
            />
          ))}
        </div>
      </>
    );
  }
}

export const PREFIX_1 = 'เด็กตกเป็นเหยื่อและเสี่ยงต่อ';
export const PREFIX_2 = 'เด็กที่';
export const PREFIX_3 = 'เด็กที่ขาด';

/** https://github.com/microsoft/TypeScript/issues/28046#issuecomment-431871542 **/
export type ElementType<
  T extends ReadonlyArray<unknown>
> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export const notEmpty = (v: string | undefined) => v !== undefined && v !== '';
export const empty = (v: string | undefined) => v === undefined || v === '';
