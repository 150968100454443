import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { LineList } from './lineList';
import { Detail } from './detail';
import { reducer, Case } from './state';
import { apiURL } from '../config';
import { DataState } from '../report/data';

type FetchReportsResponse = {
  success: boolean;
  total: number;
  pageTotal: number;
  reports: {
    id: number;
    name: string;
    age: string;
    gender: string;
    description: string;
    date: Date;
    data: DataState;
  }[];
  error?: {
    code: string;
    message: string;
  };
};

function Activity() {
  const [state, dispatch] = React.useReducer(reducer, {
    pageNumber: 0,
    cases: [],
  });

  React.useEffect(() => {
    let lineId = window.uid;
    if (!lineId) {
      const tmp = sessionStorage.getItem('userId');
      if (tmp) {
        lineId = tmp;
      }
    }

    console.log('call with uid', lineId);
    axios
      .request<FetchReportsResponse>({
        url: `${apiURL}/managedReports`,
        method: 'get',
        timeout: 15000,
        params: {
          lineId,
        },
      })
      .then(({ data }) => {
        if (data.reports) {
          const payload = data.reports.map(report => ({
            ...report,
            date: new Date(report.date),
          }));
          dispatch({
            type: 'cases',
            payload,
          });
        }
      });
  }, []);

  const selectCase = (id: number) => {
    const selected = state.cases.find((it: Case) => it.id === id);
    dispatch({
      type: 'select',
      payload: selected as Case,
    });
  };

  return (
    <Container>
      {state.pageNumber === 0 ? (
        <LineList cases={state.cases} onSelect={selectCase} />
      ) : null}
      {state.pageNumber === 1 ? (
        <Detail
          detail={state.selected}
          onBack={() => {
            dispatch({
              type: 'back',
            });
          }}
        />
      ) : null}
    </Container>
  );
}

export default Activity;
