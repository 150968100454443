import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QDRGCriteriaKeys, QDRGKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QDRGDefinition = Definition &
  SubCriteriaDefinition<QDRGCriteriaKeys>;

export const qDRGDef: QDRGDefinition = {
  id: 'qdrg',
  need: 'ถูกละเมิดหรือไม่มีคนคุ้มครอง',
  category: 'เกี่ยวกับยาเสพติด',
  label: `เด็กตกเป็นเหยื่อและมีความเสี่ยงเกี่ยวกับยาเสพติด`,
  subCriteriaLabel: 'ลักษณะความเสี่ยง',
  subCriteriaChoices: {
    qdrg_1_1: 'เสพ',
    qdrg_1_2: 'ขายยาเสพติด',
    qdrg_1_3: 'คนใกล้ชิดเกี่ยวข้องกับยาเสพติด',
    qdrg_1_4: 'อื่นๆ',
  },
  subCriteriaKeys: QDRGKeys,
};

export function copyQDRGDependentValues(source: DataState, target: DataState) {
  if (source.qdrg) {
    target.qdrg = true;
    QDRGKeys.forEach(k => (target[k] = source[k] || false));
    target.qdrg_text = source.qdrg_text;
  }
}

export function QDRGDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qdrg) {
    return (
      <div>
        {qDRGDef.need} จาก {qDRGDef.category} {qDRGDef.subCriteriaLabel} คือ
        <ul>
          {qDRGDef.subCriteriaKeys.map((k: QDRGCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qDRGDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qdrg_text ? <div>คำอธิบายเพิ่มเติม: {data.qdrg_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QDRG(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qdrg_1_4
    ? notEmpty(data.qdrg_text)
    : data.qdrg_1_1 || data.qdrg_1_2 || data.qdrg_1_3;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qDRGDef.label}</div>
        <SubCriteriaCheckboxes<QDRGCriteriaKeys>
          definition={qDRGDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qdrg_text">
            <Form.Control
              type="text"
              value={data.qdrg_text || ''}
              isInvalid={data.qdrg_1_4 && empty(data.qdrg_text)}
              onChange={(evt: any) => set('qdrg_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
