import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_2,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QPORCriteriaKeys, QPORKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QPORDefinition = Definition &
  SubCriteriaDefinition<QPORCriteriaKeys>;

export const qPORDef: QPORDefinition = {
  id: 'qpor',
  need: 'ต้องการสวัสดิการทางการศึกษา',
  category: 'เด็กยากจน',
  label: `${PREFIX_2}ยากจน`,
  subCriteriaLabel: 'ลักษณะ',
  subCriteriaChoices: {
    qpor_1_1: 'เด็กได้เรียนแต่เสี่ยงต้องหยุด เช่น ต้องหยุดเรียนบ่อย เป็นต้น',
    qpor_1_2: 'ต้องออกจากโรงเรียนเพราะไม่มีเงินเรียนต่อ',
    qpor_1_3: 'กำลังสมัครเรียนต่อแต่ไม่รู้จะไปหาทุนที่ไหน',
    qpor_1_4: 'เรียนจบแล้วแต่ไม่ได้วุฒิเพราะไม่ได้จ่ายค่าเทอม',
    qpor_1_5: 'อื่นๆ',
  },
  subCriteriaKeys: QPORKeys,
};

export function copyQPORDependentValues(source: DataState, target: DataState) {
  if (source.qpor) {
    target.qpor = true;
    QPORKeys.forEach(k => (target[k] = source[k] || false));
    target.qpor_text = source.qpor_text;
  }
}

export function QPORDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qpor) {
    return (
      <div>
        {qPORDef.need} กรณี {qPORDef.category} {qPORDef.subCriteriaLabel} คือ
        <ul>
          {qPORDef.subCriteriaKeys.map((k: QPORCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qPORDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qpor_text ? <div>คำอธิบายเพิ่มเติม: {data.qpor_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QPOR(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qpor_1_5
    ? notEmpty(data.qpor_text)
    : data.qpor_1_1 || data.qpor_1_2 || data.qpor_1_3 || data.qpor_1_4;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qPORDef.label}</div>
        <SubCriteriaCheckboxes<QPORCriteriaKeys>
          definition={qPORDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qpor_text">
            <Form.Control
              type="text"
              value={data.qpor_text || ''}
              isInvalid={data.qpor_1_5 && empty(data.qpor_text)}
              onChange={(evt: any) => set('qpor_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
