import * as React from 'react';
import { getPageInitialState, pageReducer } from '../report/page';
import { dataReducer, getDefaultDataState } from '../report/data';
import TopicPage from '../report/topicPage';
import SubTopicPage from '../report/subTopicPage';
import ConfirmPage from '../report/confirmPage';
import { BasicInfoDataState, basicInfoNextFn } from '../components/PersonForm';
import { BasicInfoForm } from '../report';
import { Fragment } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function StaffReport() {
  const [{ page }, dispatch] = React.useReducer(
    pageReducer,
    getPageInitialState(),
  );

  const [data, dataDispatch] = React.useReducer(
    dataReducer,
    getDefaultDataState(),
  );

  const [message, setMessage] = React.useState('Loading...');
  const history = useHistory();
  const [basicInfo, setBasicInfo] = React.useState<BasicInfoDataState>({});
  const name = sessionStorage.getItem('name');

  return (
    <Fragment>
      <Navbar bg="light" expand={true}>
        <Navbar.Brand href="/">
          <img src="/logo-srs.png" />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text style={{ paddingRight: 20 }}>
            ผู้ใช้งาน: {name}
          </Navbar.Text>
          <Button
            variant="light"
            onClick={() => {
              sessionStorage.removeItem('userId');
              sessionStorage.removeItem('name');
              history.replace('/');
            }}
          >
            Logout
          </Button>
        </Navbar.Collapse>
      </Navbar>
      <div>
        {page === 'general'
          ? BasicInfoForm(basicInfo, setBasicInfo, () => {
              basicInfoNextFn(basicInfo, dataDispatch, dispatch);
            })
          : null}

        {page === 'topic' ? (
          <TopicPage
            data={data}
            dispatch={dataDispatch}
            onNext={() => {
              dispatch({ type: 'next' });
              dataDispatch({
                type: 'setNumber',
                name: 'subPageIndex',
                value: 0,
              });
            }}
            onBack={() => dispatch({ type: 'back' })}
          />
        ) : null}
        {page === 'subTopic' ? (
          <SubTopicPage
            data={data}
            dispatch={dataDispatch}
            onNext={() => dispatch({ type: 'next' })}
            onBack={() => dispatch({ type: 'back' })}
          />
        ) : null}
        {page === 'confirm' ? (
          <ConfirmPage
            data={data}
            basicInfo={basicInfo}
            onBack={() => dispatch({ type: 'back' })}
            onSubmit={() => console.log('submit')}
            webapp={true}
          />
        ) : null}
      </div>
    </Fragment>
  );
}
