import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_2,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QNATCriteriaKeys, QNATKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QNATDefinition = Definition &
  SubCriteriaDefinition<QNATCriteriaKeys>;

export const qNATDef: QNATDefinition = {
  id: 'qnat',
  need: 'ต้องการสวัสดิการทางการศึกษา',
  category: 'เด็กไม่มีสัญชาติ',
  label: `${PREFIX_2}ไม่มีสัญชาติ`,
  subCriteriaLabel: 'ลักษณะ',
  subCriteriaChoices: {
    qnat_1_1: 'ไม่ได้เข้าเรียน',
    qnat_1_2: 'ไม่ได้ฉีดวัคซีน',
    qnat_1_3: 'อื่นๆ',
  },
  subCriteriaKeys: QNATKeys,
};

export function copyQNATDependentValues(source: DataState, target: DataState) {
  if (source.qnat) {
    target.qnat = true;
    QNATKeys.forEach(k => (target[k] = source[k] || false));
    target.qnat_text = source.qnat_text;
  }
}

export function QNATDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qnat) {
    return (
      <div>
        {qNATDef.need} จาก {qNATDef.category} {qNATDef.subCriteriaLabel} คือ
        <ul>
          {qNATDef.subCriteriaKeys.map((k: QNATCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qNATDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qnat_text ? <div>คำอธิบายเพิ่มเติม: {data.qnat_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QNAT(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qnat_1_3
    ? notEmpty(data.qnat_text)
    : data.qnat_1_1 || data.qnat_1_2;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qNATDef.label}</div>
        <SubCriteriaCheckboxes<QNATCriteriaKeys>
          definition={qNATDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qnat_text">
            <Form.Control
              type="text"
              value={data.qnat_text || ''}
              isInvalid={data.qnat_1_3 && empty(data.qnat_text)}
              onChange={(evt: any) => set('qnat_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
