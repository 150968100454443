import React from 'react';
import {
  Definition,
  empty,
  notEmpty,
  PREFIX_1,
  QProps,
  SubCriteriaCheckboxes,
  SubCriteriaDefinition,
} from './common';
import { DataState, QNPRCriteriaKeys, QNPRKeys } from '../data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export type QNPRDefinition = Definition &
  SubCriteriaDefinition<QNPRCriteriaKeys>;

export const qNPRDef: QNPRDefinition = {
  id: 'qnpr',
  need: 'ถูกละเมิดหรือไม่มีคนคุ้มครอง',
  category:
    'เด็กที่ไม่มีผู้ปกครองดูแล ผู้ปกครองไม่สามารถดูแลได้ หรือ ดูแลได้ไม่เหมาะสม  ',
  label: `${PREFIX_1}การที่เด็กไม่มีผู้ปกครองดูแล ผู้ปกครองไม่สามารถดูแลได้ หรือ ดูแลได้ไม่เหมาะสม  `,
  subCriteriaLabel: 'ลักษณะความเสี่ยง',
  subCriteriaChoices: {
    qnpr_1_1: 'เด็กกำพร้าหรือถูกทอดทิ้ง',
    qnpr_1_2: 'อยู่กับคนแก่หรือคนป่วยที่ช่วยเหลือตัวเองไม่ได้ ',
    qnpr_1_3: 'อยู่กับผู้ปกครองที่มีพฤติกรรมนำไปสู่อันตราย',
    qnpr_1_4:
      'ได้รับการดูแลที่ไม่เหมาะสม เช่น ติดตามผู้ปกครองไปทำงานในเวลากลางคืน เป็นต้น',
    qnpr_1_5: 'อื่นๆ',
  },
  subCriteriaKeys: QNPRKeys,
};

export function copyQNPRDependentValues(source: DataState, target: DataState) {
  if (source.qnpr) {
    target.qnpr = true;
    QNPRKeys.forEach(k => (target[k] = source[k] || false));
    target.qnpr_text = source.qnpr_text;
  }
}

export function QNPRDescribe(props: { data: DataState }) {
  const { data } = props;
  if (data.qnpr) {
    return (
      <div>
        {qNPRDef.need} จาก {qNPRDef.category} {qNPRDef.subCriteriaLabel} คือ
        <ul>
          {qNPRDef.subCriteriaKeys.map((k: QNPRCriteriaKeys) => {
            return data[k] ? (
              <li key={k}>{qNPRDef.subCriteriaChoices[k]}</li>
            ) : null;
          })}
        </ul>
        {data.qnpr_text ? <div>คำอธิบายเพิ่มเติม: {data.qnpr_text}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

export function QNPR(props: QProps) {
  const { setBoolean, data, onBack, onNext, set } = props;
  const isValid = data.qnpr_1_5
    ? notEmpty(data.qnpr_text)
    : data.qnpr_1_1 || data.qnpr_1_2 || data.qnpr_1_3 || data.qnpr_1_4;
  return (
    <Container>
      <div className="question-group mb-3">
        <div className="question-group-title">{qNPRDef.label}</div>
        <SubCriteriaCheckboxes<QNPRCriteriaKeys>
          definition={qNPRDef}
          data={data}
          setBoolean={setBoolean}
        />

        <div className="question-group-subtitle">อธิบายเพิ่มเติม</div>
        <div className="choices">
          <Form.Group controlId="qnpr_text">
            <Form.Control
              type="text"
              value={data.qnpr_text || ''}
              isInvalid={data.qnpr_1_5 && empty(data.qnpr_text)}
              onChange={(evt: any) => set('qnpr_text', evt.target.value)}
            />
          </Form.Group>
        </div>
      </div>

      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            disabled={!isValid}
            variant="primary"
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
