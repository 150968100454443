export const topics = [
  'qsxv',
  'qtrf',
  'qbmv',
  'qlab',
  'qdrg',
  'qsed',
  'qedu',
  'qnpr',
  'qprg',
  'qdis',
  'qnat',
  'qpor',
  'qlrn',
  'qfod',
  'qhou',
  'qhea',
  'qemo',
] as const;
export type TopicKeys = typeof topics[number];

export const QBMVKeys = [
  'qbmv_1_1',
  'qbmv_1_2',
  'qbmv_1_3',
  'qbmv_1_4',
  'qbmv_1_5',
] as const;
export type QBMVCriteriaKeys = typeof QBMVKeys[number];

export const QDISKeys = ['qdis_1_1', 'qdis_1_2', 'qdis_1_3'] as const;
export type QDISCriteriaKeys = typeof QDISKeys[number];

export const QDRGKeys = [
  'qdrg_1_1',
  'qdrg_1_2',
  'qdrg_1_3',
  'qdrg_1_4',
] as const;
export type QDRGCriteriaKeys = typeof QDRGKeys[number];

export const QEDUKeys = ['qedu_1_1', 'qedu_1_2', 'qedu_1_3'] as const;
export type QEDUCriteriaKeys = typeof QEDUKeys[number];

export const QEMOKeys = [
  'qemo_1_1',
  'qemo_1_2',
  'qemo_1_3',
  'qemo_1_4',
  'qemo_1_5',
  'qemo_1_6',
  'qemo_1_7',
  'qemo_1_8',
] as const;
export type QEMOCriteriaKeys = typeof QEMOKeys[number];

export const QFODKeys = [
  'qfod_1_1',
  'qfod_1_2',
  'qfod_1_3',
  'qfod_1_4',
] as const;
export type QFODCriteriaKeys = typeof QFODKeys[number];

export const QHEAKeys = [
  'qhea_1_1',
  'qhea_1_2',
  'qhea_1_3',
  'qhea_1_4',
] as const;
export type QHEACriteriaKeys = typeof QHEAKeys[number];

export const QHOUKeys = [
  'qhou_1_1',
  'qhou_1_2',
  'qhou_1_3',
  'qhou_1_4',
  'qhou_1_5',
] as const;
export type QHOUCriteriaKeys = typeof QHOUKeys[number];

export const QLABKeys = [
  'qlab_1_1',
  'qlab_1_2',
  'qlab_1_3',
  'qlab_1_4',
] as const;
export type QLABCriteriaKeys = typeof QLABKeys[number];

export const QLRNKeys = ['qlrn_1_1', 'qlrn_1_2', 'qlrn_1_3'] as const;
export type QLRNCriteriaKeys = typeof QLRNKeys[number];

export const QNATKeys = ['qnat_1_1', 'qnat_1_2', 'qnat_1_3'] as const;
export type QNATCriteriaKeys = typeof QNATKeys[number];

export const QNPRKeys = [
  'qnpr_1_1',
  'qnpr_1_2',
  'qnpr_1_3',
  'qnpr_1_4',
  'qnpr_1_5',
] as const;
export type QNPRCriteriaKeys = typeof QNPRKeys[number];

export const QPORKeys = [
  'qpor_1_1',
  'qpor_1_2',
  'qpor_1_3',
  'qpor_1_4',
  'qpor_1_5',
] as const;
export type QPORCriteriaKeys = typeof QPORKeys[number];

export const QPRGKeys = ['qprg_1_1', 'qprg_1_2', 'qprg_1_3'] as const;
export type QPRGCriteriaKeys = typeof QPRGKeys[number];

export const QSEDKeys = ['qsed_1_1', 'qsed_1_2', 'qsed_1_3'] as const;
export type QSEDCriteriaKeys = typeof QSEDKeys[number];

export const QSXVKeys = ['qsxv_1_1', 'qsxv_1_2', 'qsxv_1_3'] as const;
export type QSXVCriteriaKeys = typeof QSXVKeys[number];

export const QTRFKeys = [
  'qtrf_1_1',
  'qtrf_1_2',
  'qtrf_1_3',
  'qtrf_1_4',
] as const;
export type QTRFCriteriaKeys = typeof QTRFKeys[number];

export type BooleanKeys =
  | QBMVCriteriaKeys
  | QDISCriteriaKeys
  | QDRGCriteriaKeys
  | QEDUCriteriaKeys
  | QEMOCriteriaKeys
  | QFODCriteriaKeys
  | QHEACriteriaKeys
  | QHOUCriteriaKeys
  | QLABCriteriaKeys
  | QLRNCriteriaKeys
  | QNATCriteriaKeys
  | QNPRCriteriaKeys
  | QPORCriteriaKeys
  | QPRGCriteriaKeys
  | QSEDCriteriaKeys
  | QSXVCriteriaKeys
  | QTRFCriteriaKeys;

export type TopicQuestion = {
  [K in TopicKeys]: boolean;
};
export type BooleanQuestions = {
  [K in BooleanKeys]?: boolean;
};

export const textKeys = [
  'qsxv_text',
  'qtrf_text',
  'qbmv_text',
  'qlab_text',
  'qdrg_text',
  'qsed_text',
  'qnpr_text',
  'qedu_text',
  'qprg_text',
  'qdis_text',
  'qnat_text',
  'qpor_text',
  'qlrn_text',
  'qfod_text',
  'qhou_text',
  'qhea_text',
  'qemo_text',
] as const;
export type TextKeys = typeof textKeys[number];
export type TextQuestions = {
  [K in TextKeys]?: string;
};

export type DataState = TopicQuestion &
  BooleanQuestions &
  TextQuestions & {
    subPages?: string[]; // หน้าทั้งหมดที่เป็นไปได้ จากการ tick ที่หน้าแรก
    subPageIndex?: number; // หน้าล่าสุด มีค่าระหว่าง 0 ถึง subPages.length - 1

    name?: string;
    age?: string;
    gender?: 'male' | 'female';
    location?: string;
  };

export type DataAction =
  | {
      type: 'set';
      name: string;
      value: string;
    }
  | {
      type: 'setBoolean';
      name: string;
      value: boolean;
    }
  | { type: 'setNumber'; name: string; value: number };

/*
 * return array of topic that equal true
 * ex. state.q1 = true, state.q3 = true -> ['q1', 'q3]
 */
function subPages(state: DataState): string[] {
  return topics.map(k => (state[k] ? k : '')).filter(v => v !== '');
}

export function getDefaultTopicData(): TopicQuestion {
  return {
    qsxv: false,
    qtrf: false,
    qbmv: false,
    qlab: false,
    qdrg: false,
    qsed: false,
    qedu: false,
    qnpr: false,
    qprg: false,
    qdis: false,
    qnat: false,
    qpor: false,
    qlrn: false,
    qfod: false,
    qhou: false,
    qhea: false,
    qemo: false,
  };
}

export function getDefaultDataState(): DataState {
  return {
    ...getDefaultTopicData(),
  };
}

export function dataReducer(state: DataState, action: DataAction) {
  let nextState: DataState;
  let pages: string[];
  switch (action.type) {
    case 'set':
      nextState = {
        ...state,
        [action.name]: action.value,
      };
      pages = subPages(nextState);
      return {
        ...nextState,
        subPages: pages,
      };
    case 'setBoolean':
      nextState = {
        ...state,
        [action.name]: action.value,
      };
      pages = subPages(nextState);
      return {
        ...nextState,
        subPages: pages,
      };
    case 'setNumber':
      nextState = {
        ...state,
        [action.name]: action.value,
      };
      pages = subPages(nextState);
      return {
        ...nextState,
        subPages: pages,
      };
  }
  return state;
}
