import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { DataAction, DataState } from './data';

function generateAgeChoices() {
  const data = [];
  for (let i = 1; i <= 18; i++) {
    data.push({ label: i.toString(), value: i.toString() });
  }
  data.push({
    label: 'ระบุไม่ได้',
    value: 'n/a',
  });
  return data;
}
type GeneralPagePropsType = {
  data: DataState;
  dispatch: (action: DataAction) => void;
  onNext: () => void;
  geoLocation: boolean;
};

function GeneralPage(props: GeneralPagePropsType) {
  const { data, dispatch, onNext, geoLocation } = props;
  const ageChoices = generateAgeChoices();
  const setName = (value: string) =>
    dispatch({ type: 'set', name: 'name', value: value });
  const setAge = (value: string) =>
    dispatch({ type: 'set', name: 'age', value: value });
  const setGender = (value: string) =>
    dispatch({ type: 'set', name: 'gender', value: value });
  const validName = data.name !== '' && data.name !== undefined;
  const validAge = data.age !== '' && data.age !== undefined;
  const validGender = data.gender === 'male' || data.gender === 'female';
  const validForm = validName && validAge && validGender;

  React.useEffect(() => {
    if (geoLocation) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          dispatch({
            type: 'set',
            name: 'location',
            value: `${position.coords.latitude}, ${position.coords.longitude}`,
          });
        });
      }
    }
  }, [geoLocation]);

  return (
    <Container>
      <div className="page-title">ข้อมูลเบื้องต้น</div>
      <Form inline={false}>
        <div className="question-group mb-3">
          <Form.Group controlId="name">
            <Form.Label>ชื่อและนามสกุลเด็ก</Form.Label>
            <Form.Control
              type="text"
              value={data.name}
              isValid={validName}
              onChange={(evt: any) => setName(evt.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="age">
            <Form.Label>อายุ</Form.Label>
            <Form.Control
              as="select"
              value={data.age || ''}
              isValid={validAge}
              onChange={(evt: any) => setAge(evt.target.value)}
              custom="true"
            >
              <option value="" />
              {ageChoices.map(item => (
                <option key={item.label} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="gender">
            <Form.Label>เพศ</Form.Label>
            <Form.Control
              as="select"
              value={data.gender}
              isValid={validGender}
              onChange={(evt: any) => setGender(evt.target.value)}
              custom
            >
              <option value="" />
              <option value="male">ชาย</option>
              <option value="female">หญิง</option>
            </Form.Control>
          </Form.Group>
        </div>
      </Form>

      <Row>
        <Col className="text-right">
          <Button
            disabled={!validForm}
            className="next-btn"
            variant="primary"
            onClick={() => onNext()}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default GeneralPage;
