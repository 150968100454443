import React from 'react';
import axios from 'axios';
import { Button, Col, Container, Form, Alert } from 'react-bootstrap';
import './index.css';
import DatePickerField, { formatDate } from '../components/DatePickerField';
import AsyncButton from '../components/AsyncButton';
import { apiURL } from '../config';
import { Activity } from './interface';

type DetailFormProps = {
  onBack: (result: Activity | null) => void;
  reportId: number;
};

export function DetailForm(props: DetailFormProps) {
  const reportId = props.reportId;
  const [error, setError] = React.useState<string | null>(null);
  const [activity, setActivity] = React.useState('');
  const [date, setDate] = React.useState(new Date());
  const [image, setImage] = React.useState();
  const [document, setDocument] = React.useState();

  const validActivity = activity !== '';
  const validDate = date !== null;
  const validForm: boolean = validActivity && validDate;
  const submit = () => {
    console.log('submit data', activity, formatDate(date), image, document);

    let lineId = window.uid;
    if (!lineId) {
      const tmp = sessionStorage.getItem('userId');
      if (tmp) {
        lineId = tmp;
      }
    }
    const data = new FormData();
    data.set('lineId', lineId);
    data.set('reportId', reportId.toString());
    data.set('description', activity);
    data.set('date', formatDate(date));
    data.append('image', image);
    data.append('document', document);
    console.log('submit', data);
    return axios
      .post(`${apiURL}/managedReports/${reportId}/activities`, data, {})
      .then(res => {
        console.log(res.statusText);
        props.onBack({
          id: new Date().getTime(),
          description: activity,
          date: date,
        });
      })
      .catch(() => {
        setError('ไม่สามารถสร้างกิจกรรมได้');
      });
  };

  const checkMimeType = (event: any) => {
    let files = event.target.files;
    let err = '';
    // list allow mime type
    const types = ['image/png', 'image/jpeg', 'image/gif'];

    for (let x = 0; x < files.length; x++) {
      // find file type that doesn't match
      if (types.every(type => files[x].type !== type)) {
        err += files[x].type + ' รูปแบบไฟล์ไม่ถูกต้อง\n';
      }
    }

    setError(err);
    if (err !== '') {
      event.target.value = null; // discard selected file
      return false;
    }
    return true;
  };

  const checkFileSize = (event: any, maxByteSize: number) => {
    let files = event.target.files;
    let size = maxByteSize || 1000000; // default to 1mb
    let err = '';
    for (let x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err += files[x].type + ' ขนาดไฟล์ใหญ่เกินไป เลือกไฟล์เล็กกว่านี้\n';
      }
    }

    setError(err);
    if (err !== '') {
      event.target.value = null;
      return false;
    }
    return true;
  };

  return (
    <Container>
      <Alert
        style={{
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
        }}
        variant="danger"
        show={error !== null && error !== ''}
        onClose={() => setError(null)}
        dismissible
      >
        {error}
      </Alert>
      <Form>
        <Form.Group controlId="activity">
          <Form.Label>เหตุการณ์/กิจกรรม</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            value={activity}
            onChange={(evt: any) => setActivity(evt.target.value)}
          />
        </Form.Group>
        <DatePickerField value={date} onChange={setDate} />
        <div className="form-group files">
          <label>แนบรูปภาพ</label>
          <input
            type="file"
            className="form-control"
            multiple={false}
            onChange={(evt: any) => {
              if (checkMimeType(evt) && checkFileSize(evt, 1500000)) {
                setImage(evt.target.files[0]);
              }
            }}
          />
          <small className="form-text text-muted">
            เฉพาะไฟล์รูปภาพ ขนาดไม่เกิน 1.5Mb
          </small>
        </div>
        <div className="form-group files color">
          <label>แนบเอกสาร</label>
          <input
            type="file"
            className="form-control"
            multiple={false}
            onChange={(evt: any) => {
              if (checkFileSize(evt, 1500000)) {
                setDocument(evt.target.files[0]);
              }
            }}
          />
          <small className="form-text text-muted">
            ไฟล์อื่นๆขนาดไม่เกิน 1.5Mb
          </small>
        </div>
        <Form.Row>
          <Col>
            <Button variant="light" onClick={() => props.onBack(null)}>
              &lt; Back
            </Button>
          </Col>
          <Col className="text-right">
            <AsyncButton
              variant="primary"
              disabled={!validForm}
              onClick={submit}
            >
              บันทึกกิจกรรม
            </AsyncButton>
          </Col>
        </Form.Row>
      </Form>
    </Container>
  );
}
