import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import Public from './spa/public';
import Spa from './spa';
import AnonymousReport from './spa/anonymousReport';
import StaffReport from './spa/staffReport';

declare global {
  interface Window {
    uid: string;
  }
}

function App() {
  return (
    <Router>
      <Route path="/public" component={Public} exact />
      <Route path="/public/report" component={AnonymousReport} exact />
      <Route path="/" component={Spa} exact />
      <Route path="/report" component={StaffReport} exact />
    </Router>
  );
}

export default App;
