import React from 'react';
import { DataAction, DataState } from './data';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { qSXVDef } from './questions/qSXV';
import { qTRFDef } from './questions/qTRF';
import { qBMVDef } from './questions/qBMV';
import { qLABDef } from './questions/qLAB';
import { qDRGDef } from './questions/qDRG';
import { qSEDDef } from './questions/qSED';
import { qNPRDef } from './questions/qNPR';
import { qEDUDef } from './questions/qEDU';
import { qPRGDef } from './questions/qPRG';
import { qDISDef } from './questions/qDIS';
import { qNATDef } from './questions/qNAT';
import { qPORDef } from './questions/qPOR';
import { qLRNDef } from './questions/qLRN';
import { qFODDef } from './questions/qFOD';
import { qHOUDef } from './questions/qHOU';
import { qHEADef } from './questions/qHEA';
import { qEMODef } from './questions/qEMO';

type QuestionsType = {
  label: string;
  name: string;
  value: boolean | undefined;
  dispatch: (action: DataAction) => void;
};
export const QuestionsGroup = ({
  label,
  name,
  value,
  dispatch,
}: QuestionsType) => {
  return (
    <div className="mb-3">
      <Form.Check
        custom
        type="checkbox"
        id={name}
        checked={value}
        label={label}
        onChange={(evt: any) => {
          console.log(evt.target.checked);
          dispatch({
            type: 'setBoolean',
            name: name,
            value: evt.target.checked,
          });
        }}
      />
    </div>
  );
};

type TopicPagePropsType = {
  data: DataState;
  dispatch: (action: DataAction) => void;
  onNext: () => void;
  onBack: () => void;
};

function TopicPage(props: TopicPagePropsType) {
  const { data, dispatch, onNext, onBack } = props;
  const isValid =
    data.qsxv ||
    data.qtrf ||
    data.qbmv ||
    data.qlab ||
    data.qdrg ||
    data.qsed ||
    data.qedu ||
    data.qnpr ||
    data.qprg ||
    data.qdis ||
    data.qnat ||
    data.qpor ||
    data.qlrn ||
    data.qfod ||
    data.qhou ||
    data.qhea ||
    data.qemo;
  return (
    <Container>
      <Form>
        <div className="page-title">
          ท่านพบเจอเด็กที่อยู่ในสภาวะหรือมีความเสี่ยงในด้านใด ?
        </div>

        <div className="question-group mb-3">
          <div className="question-group-title">
            ถูกละเมิดหรือไม่มีคนคุ้มครอง
          </div>
          <div className="choices">
            <div className="mb-3" />
            <QuestionsGroup
              name="qsxv"
              value={data.qsxv}
              label={qSXVDef.label}
              dispatch={dispatch}
            />

            <QuestionsGroup
              label={qTRFDef.label}
              name="qtrf"
              value={data.qtrf}
              dispatch={dispatch}
            />

            <QuestionsGroup
              label={qBMVDef.label}
              name="qbmv"
              value={data.qbmv}
              dispatch={dispatch}
            />

            <QuestionsGroup
              name="qlab"
              value={data.qlab}
              label={qLABDef.label}
              dispatch={dispatch}
            />

            <QuestionsGroup
              name="qdrg"
              value={data.qdrg}
              label={qDRGDef.label}
              dispatch={dispatch}
            />

            <QuestionsGroup
              name="qsed"
              value={data.qsed}
              label={qSEDDef.label}
              dispatch={dispatch}
            />

            <QuestionsGroup
              name="qnpr"
              value={data.qnpr}
              label={qNPRDef.label}
              dispatch={dispatch}
            />
          </div>
        </div>

        <div className="question-group mb-3">
          <div className="question-group-title">
            ต้องการสวัสดิการทางการศึกษา
          </div>
          <div className="choices">
            <div className="mb-3" />

            <QuestionsGroup
              name="qedu"
              value={data.qedu}
              label={qEDUDef.label}
              dispatch={dispatch}
            />
            <QuestionsGroup
              name="qprg"
              value={data.qprg}
              label={qPRGDef.label}
              dispatch={dispatch}
            />
            <QuestionsGroup
              name="qdis"
              value={data.qdis}
              label={qDISDef.label}
              dispatch={dispatch}
            />
            <QuestionsGroup
              name={qNATDef.id}
              value={data.qnat}
              label={qNATDef.label}
              dispatch={dispatch}
            />
            <QuestionsGroup
              name={qPORDef.id}
              value={data.qpor}
              label={qPORDef.label}
              dispatch={dispatch}
            />
            <QuestionsGroup
              name={qLRNDef.id}
              value={data.qlrn}
              label={qLRNDef.label}
              dispatch={dispatch}
            />
          </div>
        </div>

        <div className="question-group mb-3">
          <div className="question-group-title">
            ต้องการความช่วยเหลือด้านอื่นๆ
          </div>
          <div className="choices">
            <div className="mb-3" />

            <QuestionsGroup
              name={qFODDef.id}
              value={data.qfod}
              label={qFODDef.label}
              dispatch={dispatch}
            />
            <QuestionsGroup
              name={qHOUDef.id}
              value={data.qhou}
              label={qHOUDef.label}
              dispatch={dispatch}
            />
            <QuestionsGroup
              name={qHEADef.id}
              value={data.qhea}
              label={qHEADef.label}
              dispatch={dispatch}
            />
            <QuestionsGroup
              name={qEMODef.id}
              value={data.qemo}
              label={qEMODef.label}
              dispatch={dispatch}
            />
          </div>
        </div>
      </Form>
      <Row>
        <Col className="text-left">
          <Button className="btn-light" onClick={onBack}>
            ย้อนกลับ
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            className="next-btn"
            variant="primary"
            disabled={!isValid}
            onClick={onNext}
          >
            ต่อไป
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default TopicPage;
