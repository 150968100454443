import React from 'react';
import { DataAction, DataState } from './data';
import { Container } from 'react-bootstrap';
import { QSXV } from './questions/qSXV';
import { QTRF } from './questions/qTRF';
import { QDRG } from './questions/qDRG';
import { QSED } from './questions/qSED';
import { QEDU } from './questions/qEDU';
import { QNPR, qNPRDef } from './questions/qNPR';
import { QPRG, qPRGDef } from './questions/qPRG';
import { QPOR, qPORDef } from './questions/qPOR';
import { QLRN, qLRNDef } from './questions/qLRN';
import { QFOD, qFODDef } from './questions/qFOD';
import { QHOU, qHOUDef } from './questions/qHOU';
import { QHEA, qHEADef } from './questions/qHEA';
import { QBMV } from './questions/qBMV';
import { QLAB } from './questions/qLAB';
import { QDIS, qDISDef } from './questions/qDIS';
import { QNAT, qNATDef } from './questions/qNAT';
import { QEMO, qEMODef } from './questions/qEMO';

type SubTopicPagePropsType = {
  data: DataState;
  dispatch: (action: DataAction) => void;
  onNext: () => void;
  onBack: () => void;
};

function SubTopicPage(props: SubTopicPagePropsType) {
  const { data, dispatch, onNext, onBack } = props;

  const subPages = data.subPages || [];
  const subPageIndex = data.subPageIndex || 0;

  const set = (name: string, value: string) =>
    dispatch({ type: 'set', name, value });
  const setBoolean = (name: string, value: boolean) =>
    dispatch({ type: 'setBoolean', name, value });
  const qNext = () => {
    console.log('next');
    if (subPages && subPageIndex < subPages.length - 1) {
      dispatch({
        type: 'setNumber',
        name: 'subPageIndex',
        value: subPageIndex + 1,
      });
    } else {
      onNext();
    }
  };
  const qBack = () => {
    console.log('back');
    if (subPages && subPageIndex > 0) {
      dispatch({
        type: 'setNumber',
        name: 'subPageIndex',
        value: subPageIndex - 1,
      });
    } else {
      onBack();
    }
  };
  return (
    <Container>
      {subPages[subPageIndex] === 'qsxv' ? (
        <QSXV
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === 'qtrf' ? (
        <QTRF
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === 'qbmv' ? (
        <QBMV
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === 'qlab' ? (
        <QLAB
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === 'qdrg' ? (
        <QDRG
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === 'qsed' ? (
        <QSED
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === 'qedu' ? (
        <QEDU
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qNPRDef.id ? (
        <QNPR
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qPRGDef.id ? (
        <QPRG
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qDISDef.id ? (
        <QDIS
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qNATDef.id ? (
        <QNAT
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qPORDef.id ? (
        <QPOR
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qLRNDef.id ? (
        <QLRN
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qFODDef.id ? (
        <QFOD
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qHOUDef.id ? (
        <QHOU
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qHEADef.id ? (
        <QHEA
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
      {subPages[subPageIndex] === qEMODef.id ? (
        <QEMO
          set={set}
          setBoolean={setBoolean}
          data={data}
          onBack={qBack}
          onNext={qNext}
        />
      ) : null}
    </Container>
  );
}

export default SubTopicPage;
